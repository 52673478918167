import React from "react";
import { Layout, Typography } from "antd";
import { Link } from "react-router-dom";
const { Footer } = Layout;
const { Text } = Typography;

const SiteFooter = () => {
  return (
    <Footer className="footer">
      <Text type="secondary" style={{ color: "#ffffff" }}>
        Crafted by Clark 2024 (0926006)
      </Text>
    </Footer>
  );
};

export default SiteFooter;
