import React, { useEffect, useRef, useState } from "react";
import {
  Layout,
  Statistic,
  Space,
  Button,
  Select,
  notification,
  Typography,
} from "antd";
import numberFormatter from "number-formatter";
import { ReloadOutlined } from "@ant-design/icons";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import SiteHeader from "../Components/SiteHeader";
import SiteFooter from "../Components/SiteFooter";
import Loader from "../Components/Loader";
import LoaderRelative from "../Components/LoaderRelative";
import isNumber from "isnumber";
import { AxiosError } from "axios";
const { Content } = Layout;
const { Text, Title } = Typography;

const CustomLogics = () => {
  const axiosPrivate = useAxiosPrivate();
  const initCheck = useRef(true);
  const [hasInitialised, setHasInitialised] = useState(false);
  const [currentMetreId, setCurrentMetreId] = useState("");
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isReadingLogics, setIsReadingLogics] = useState(false);
  const [logics, setLogics] = useState([]);
  const [metreGroups, setMetreGroups] = useState([]);

  const refreshLogicReadings = async () => {
    if (!isNumber(currentMetreId)) return;
    try {
      setIsReadingLogics(true);
      const { data } = await axiosPrivate.post(
        `/regular/metres/${currentMetreId}/custom-logics/values`,
        {
          logics,
        }
      );

      const copyLogics = [...logics];
      for (let logic of copyLogics) {
        const foundValue = data.find(
          (value) => value.logicId === logic.logic_index
        );

        if (foundValue) {
          logic.value = foundValue.value;
        }
      }
      setLogics(copyLogics);
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err.response.data
            : err?.message ?? err?.toString,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsReadingLogics(false);
    }
  };

  const reloadLogics = async () => {
    if (!isNumber(currentMetreId)) return;
    try {
      setIsLoadingStatus(true);
      const { data } = await axiosPrivate.post(
        `/regular/metres/${currentMetreId}/custom-logics`,
        { with_value: true }
      );
      const logics = data;
      const { data: dataValues } = await axiosPrivate.post(
        `/regular/metres/${currentMetreId}/custom-logics/values`,
        {
          logics,
        }
      );
      const copyLogics = [...logics];
      for (let logic of copyLogics) {
        const foundValue = dataValues.find(
          (value) => value.logicId === logic.logic_index
        );

        if (foundValue) {
          logic.value = foundValue.value;
        }
      }
      setLogics(copyLogics);
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err.response.data
            : err?.message ?? err?.toString,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const loadMetreGroup = async () => {
    try {
      const resMetreGroup = await axiosPrivate.get(
        "/regular/app/read-metre-group?include_online_status=1"
      );
      const metre_groups = resMetreGroup.data;
      setMetreGroups(metre_groups);
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err.response.data
            : err?.message ?? err?.toString,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setHasInitialised(true);
    }
  };

  useEffect(() => {
    if (initCheck.current) {
      (async () => {
        await loadMetreGroup();
      })();
      initCheck.current = false;
    }
    if (hasInitialised) {
      (async () => {
        await reloadLogics(true);
      })();
    }
  }, [currentMetreId]);

  const getMetreNameByMetreId = (metreId) => {
    const metres = metreGroups.reduce((options, group) => {
      options.push(...group?.options);
      return options;
    }, []);
    //const metres = metreGroups.map((group) => group?.options);
    return metres.find((metre) => metre.value === metreId)?.label;
  };

  const decorateValue = (logic) => {
    if ("value" in logic && logic.value !== null) {
      if (logic.value_type === "string") {
        return logic.value;
      } else if (logic.value_type === "number") {
        return numberFormatter("#,###,###,##0.00", logic.value);
      } else if (logic.value_type === "boolean") {
        return Number(logic.value) ? "Yes" : "No";
      } else {
        if (isNumber(logic.value)) {
          return numberFormatter("#,###,###,##0.00", logic.value);
        } else {
          return logic.value;
        }
      }
    } else {
      return "-";
    }
  };

  return (
    <>
      <Content className="custom-logics-content-layout">
        <Content className="custom-logics-card">
          <Content className="custom-logics-title">
            <Text
              style={{
                fontSize: 28,
                color: "#434343",
                padding: "0px 10px 0px",
              }}
            >
              {`Utility - Custom Logics${
                isNumber(currentMetreId)
                  ? ` - ${getMetreNameByMetreId(currentMetreId)}`
                  : ""
              }`}
            </Text>
            <Space style={{ marginRight: 15 }}>
              <Button
                size="small"
                icon={<ReloadOutlined />}
                disabled={!isNumber(currentMetreId) ? true : false}
                loading={isReadingLogics}
                onClick={async () => {
                  await refreshLogicReadings();
                }}
              ></Button>
              <Select
                size="small"
                defaultValue={""}
                options={[
                  { label: "Select a metre...", value: "" },
                  ...metreGroups,
                ]}
                placeholder="Select a metre to show logics"
                onChange={(value) => {
                  setLogics([]);
                  setCurrentMetreId(value);
                }}
                style={{ width: 300 }}
              />
            </Space>
          </Content>
          <LoaderRelative
            tips="Loading data..."
            loading={isLoadingStatus || isReadingLogics}
          />
          <div
            className={
              logics.length
                ? "custom-logics-main-content"
                : "custom-logics-main-content-empty"
            }
          >
            {metreGroups.length > 0 ? (
              logics.length ? (
                logics.map((logic) => {
                  return (
                    <Statistic
                      key={logic.logic_id}
                      loading={isReadingLogics}
                      precision={logic.value_type === "number" ? 2 : 0}
                      className="custom-logics-tile-statistics"
                      title={logic.display_name}
                      value={
                        isLoadingStatus || isReadingLogics
                          ? "..."
                          : decorateValue(logic)
                      }
                      valueStyle={{ color: "#3f8600" }}
                    />
                  );
                })
              ) : (
                <div className="custom-logics-no-metre-data">
                  <Title style={{ color: "#bfbfbf" }}>
                    {isLoadingStatus || isReadingLogics
                      ? ""
                      : isNumber(currentMetreId)
                      ? "No custom logics found"
                      : "Please select a metre"}
                  </Title>
                </div>
              )
            ) : (
              <div className="custom-logics-no-metre-data">
                <Title style={{ color: "#bfbfbf" }}>
                  No online or permitted connectors
                </Title>
              </div>
            )}
          </div>
        </Content>
      </Content>
    </>
  );
};

export default CustomLogics;
