import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import "antd/dist/reset.css";
import "./App.css";
import { Layout } from "antd";
import RequireAuth from "./Components/RequireAuth";
import PersistLogin from "./Components/PersistLogin";
import SiteHeader from "./Components/SiteHeader";
import SiteFooter from "./Components/SiteFooter";
import Overview from "./Pages/Overview";
// import Cards from "./Pages/Cards";
// import Totalizers from "./Pages/Totalizers";
// import Transactions from "./Pages/Transactions";
import AdminLayout from "./Pages/Admin/Layout";
import AdminMetres from "./Pages/Admin/Metres";
import AdminPresets from "./Pages/Admin/Presets";
import Profile from "./Pages/Admin/Profile";
import CustomLogics from "./Pages/CustomLogics";
// import ApplicationSettings from "./Pages/Admin/AppSettings";
// import SMTPSetting from "./Pages/Admin/AppSmtpSetting";
// import OfficeLocation from "./Pages/Admin/OfficeLocation";
import AdminUsers from "./Pages/Admin/Users";
// import AdminConnectors from "./Pages/Admin/Connectors";
// import AdminLogic from "./Pages/Admin/Logic";
// import AdminProducts from "./Pages/Admin/Products";
// import AdminGauges from "./Pages/Admin/Gauges";
// import AdminGaugeTanks from "./Pages/Admin/GaugeTanks";
// import AdminUnprocessedBols from "./Pages/Admin/UnprocessedBols";
// import Gauges from "./Pages/Gauges";
// import Deliveries from "./Pages/Deliveries";
// import InventoryHistory from "./Pages/InventoryHistory";
// import OffTheRackLoads from "./Pages/OffTheRackLoads";
// import Clients from "./Pages/Clients";
// import Logs from "./Pages/Logs";
// import Dittos from "./Pages/Dittos";
// import BayMonitor from "./Pages/BayMonitor";
import Login from "./Pages/Login";
import Page403 from "./Pages/403";
import Page404 from "./Pages/404";
import LoaderRelative from "./Components/LoaderRelative";
import SiteStatus from "./Components/SiteStatus";
const ROLES = {
  User: "1000",
  Operator: "2000",
  Admin: "8000",
  SysAdmin: "9000",
};

const Transactions = lazy(() => import("./Pages/Transactions"));
const Clients = lazy(() => import("./Pages/Clients"));
const Cards = lazy(() => import("./Pages/Cards"));
const Gauges = lazy(() => import("./Pages/Gauges"));
const Totalizers = lazy(() => import("./Pages/Totalizers"));
const Deliveries = lazy(() => import("./Pages/Deliveries"));
const InventoryHistory = lazy(() => import("./Pages/InventoryHistory"));
const OffTheRackLoads = lazy(() => import("./Pages/OffTheRackLoads"));
const Logs = lazy(() => import("./Pages/Logs"));
const Dittos = lazy(() => import("./Pages/Dittos"));
const BayMonitor = lazy(() => import("./Pages/BayMonitor"));

const AdminConnectors = lazy(() => import("./Pages/Admin/Connectors"));
const AdminUnprocessedBols = lazy(() =>
  import("./Pages/Admin/UnprocessedBols")
);
const AdminGauges = lazy(() => import("./Pages/Admin/Gauges"));
const AdminProducts = lazy(() => import("./Pages/Admin/Products"));
const AdminLogic = lazy(() => import("./Pages/Admin/Logic"));
const AdminGaugeTanks = lazy(() => import("./Pages/Admin/GaugeTanks"));

const OfficeLocation = lazy(() => import("./Pages/Admin/OfficeLocation"));
const SMTPSetting = lazy(() => import("./Pages/Admin/AppSmtpSetting"));
const ApplicationSettings = lazy(() => import("./Pages/Admin/AppSettings"));

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<PersistLogin />}>
        <Route element={<SiteStatus />}>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.User,
                  ROLES.Operator,
                  ROLES.Admin,
                  ROLES.SysAdmin,
                ]}
              />
            }
          >
            <Route path="/" element={<NavWrapper />}>
              <Route path="/" element={<Overview />} />
              <Route
                path="/transactions"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <Transactions />
                  </Suspense>
                }
              />
              <Route
                path="/customers"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <Clients />
                  </Suspense>
                }
              />
              <Route
                path="/custom-logics"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <CustomLogics />
                  </Suspense>
                }
              />
              <Route
                path="/totalizers"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <Totalizers />
                  </Suspense>
                }
              />
              <Route
                path="/gauges"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <Gauges />
                  </Suspense>
                }
              />
              <Route
                path="/delivery-history"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <Deliveries />
                  </Suspense>
                }
              />
              <Route
                path="/inventory-history"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <InventoryHistory />
                  </Suspense>
                }
              />
              <Route
                path="/load-history"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <OffTheRackLoads />
                  </Suspense>
                }
              />
              <Route
                path="/totalizers/:metre_id"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <Totalizers />
                  </Suspense>
                }
              />
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[ROLES.Operator, ROLES.Admin, ROLES.SysAdmin]}
                  />
                }
              >
                <Route
                  path="/cards/"
                  element={
                    <Suspense
                      fallback={
                        <div className="suspense-fallback">
                          <LoaderRelative loading tips="Loading page..." />
                        </div>
                      }
                    >
                      <Cards />
                    </Suspense>
                  }
                />
                <Route
                  path="/metres/:metre_id/cards"
                  element={
                    <Suspense
                      fallback={
                        <div className="suspense-fallback">
                          <LoaderRelative loading tips="Loading page..." />
                        </div>
                      }
                    >
                      <Cards />
                    </Suspense>
                  }
                />
                <Route
                  path="/logs"
                  element={
                    <Suspense
                      fallback={
                        <div className="suspense-fallback">
                          <LoaderRelative loading tips="Loading page..." />
                        </div>
                      }
                    >
                      <Logs />
                    </Suspense>
                  }
                />
                <Route
                  path="/rcu-dittos"
                  element={
                    <Suspense
                      fallback={
                        <div className="suspense-fallback">
                          <LoaderRelative loading tips="Loading page..." />
                        </div>
                      }
                    >
                      <Dittos />
                    </Suspense>
                  }
                />
                <Route
                  path="/bay-monitor"
                  element={
                    <Suspense
                      fallback={
                        <div className="suspense-fallback">
                          <LoaderRelative loading tips="Loading page..." />
                        </div>
                      }
                    >
                      <BayMonitor />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.SysAdmin]} />}
        >
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<Profile />}></Route>
            <Route path="users" element={<AdminUsers />}></Route>
            <Route
              path="office-location"
              element={
                <Suspense
                  fallback={
                    <div className="suspense-fallback">
                      <LoaderRelative loading tips="Loading page..." />
                    </div>
                  }
                >
                  <OfficeLocation />
                </Suspense>
              }
            ></Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.SysAdmin]} />}>
              <Route
                path="settings"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <ApplicationSettings />
                  </Suspense>
                }
              ></Route>
              <Route
                path="smtp-setting"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <SMTPSetting />
                  </Suspense>
                }
              ></Route>
              <Route
                path="metres"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminMetres />
                  </Suspense>
                }
              ></Route>
              <Route
                path="metres/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminMetres />
                  </Suspense>
                }
              ></Route>
              <Route
                path="metres/:id/presets"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminPresets />
                  </Suspense>
                }
              ></Route>
              <Route
                path="metres/:metre_id/products"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminProducts />
                  </Suspense>
                }
              ></Route>
              <Route
                path="metres/:metre_id/logics"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminLogic />
                  </Suspense>
                }
              ></Route>
              <Route
                path="connectors"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminConnectors />
                  </Suspense>
                }
              ></Route>
              <Route
                path="gauges"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminGauges />
                  </Suspense>
                }
              ></Route>
              <Route
                path="gauges/:gauge_id/tanks"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminGaugeTanks />
                  </Suspense>
                }
              ></Route>
              <Route
                path="unprocessed-bols"
                element={
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <LoaderRelative loading tips="Loading page..." />
                      </div>
                    }
                  >
                    <AdminUnprocessedBols />
                  </Suspense>
                }
              ></Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="/403" element={<Page403 />}></Route>
      <Route path="/404" element={<Page404 />}></Route>
      <Route path="*" element={<Navigate to="/404" />}></Route>
    </Routes>
  );
}

const NavWrapper = () => {
  return (
    <Layout className="layout">
      <SiteHeader />
      <Suspense>
        <Outlet />
      </Suspense>
      <SiteFooter />
    </Layout>
  );
};

export default App;
