import React, { useState, useEffect, useRef } from "react";
import {
  Space,
  Typography,
  Dropdown,
  Input,
  Avatar,
  Modal,
  Form,
  Layout,
  Select,
  notification,
  Image,
} from "antd";
import { UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import useLogout from "../context/hooks/useLogout";
import useAuth from "../context/hooks/useAuth";
import jwt_decode from "jwt-decode";
import CompanyLogo from "./CompanyLogo";
import { AxiosError } from "axios";

const SiteHeader = () => {
  const { Content } = Layout;
  const { Text } = Typography;
  const logout = useLogout();
  const navigate = useNavigate();
  const [frmSecurity] = Form.useForm();
  const [frmProfile] = Form.useForm();
  const [isSecurityModalOpen, setIsSecurityModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isSubmittingData, setIsSubmittingDate] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [officeLocations, setOfficeLocations] = useState([]);
  const { auth } = useAuth();
  const [modal, contextHolderConfirm] = Modal.useModal();
  const axiosPrivate = useAxiosPrivate();
  const current_location = useLocation();
  const current_pathname = current_location.pathname;

  let parsedCookies = {};
  if (auth.accessToken) {
    parsedCookies = jwt_decode(auth.accessToken);
  }
  const { roles } = parsedCookies.UserInfo;

  // const loadCompanyLogo = async () => {
  //   try {
  //     const { success, company_logo } = (await axiosPrivate.get("company-logo"))
  //       .data;
  //     setCompanyLogo(success ? company_logo : null);
  //   } catch (err) {
  //     setCompanyLogo(null);
  //   }
  // };
  const initCheck = useRef(true);
  useEffect(() => {
    if (initCheck.current) {
      initCheck.current = false;
      (async () => {
        try {
          const { data } = await axiosPrivate.get(
            `/regular/app/read-office-location`
          );

          const { office_locations } = data;
          setOfficeLocations(office_locations);
        } catch (err) {
          notification.error({
            message:
              err instanceof AxiosError
                ? err?.response?.data
                : err?.message ?? err?.toString(),
            placement: "bottom",
            duration: 4,
          });
        } finally {
        }
      })();
    }
  }, []);

  const signOut = async () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to sign out?",
      onOk: async () => {
        await logout();
        navigate("/");
      },
    });
  };

  const updateSecurityCallback = async (data) => {
    try {
      setIsSubmittingDate(true);
      const resSecurity = await axiosPrivate.post("/regular/security", {
        ...data,
      });
      setIsSecurityModalOpen(false);
      notification.success({
        message: "Successfully updated your password.",
        placement: "bottom",
        duration: 4,
      });
      await logout();
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsSubmittingDate(false);
    }
  };

  const updateProfileCallback = async (data) => {
    try {
      setIsSubmittingDate(true);
      const resProfile = await axiosPrivate.post("/regular/profile", {
        ...data,
      });
      notification.success({
        message: "Successfully updated profile.",
        placement: "bottom",
        duration: 4,
      });
      setIsProfileModalOpen(false);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsSubmittingDate(false);
    }
  };

  const loadProfile = async () => {
    try {
      setIsLoadingData(true);
      const resProfile = await axiosPrivate.get("/regular/profile");
      const { display_name, department, location, email, mobile } =
        resProfile.data;
      frmProfile.setFieldsValue({
        display_name,
        department,
        location,
        email,
        mobile,
      });
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
      setIsProfileModalOpen(false);
    } finally {
      setIsLoadingData(false);
    }
  };

  return (
    <div className="main-nav">
      {contextHolderConfirm}
      <Modal
        title={`Security`}
        open={isSecurityModalOpen}
        onOk={() => {
          frmSecurity.submit();
        }}
        okText="Save"
        okButtonProps={{
          disabled: isSubmittingData,
          loading: isSubmittingData,
          htmlType: "submit",
        }}
        closable={false}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setIsSecurityModalOpen(false);
        }}
        afterClose={() => {
          frmSecurity.resetFields();
        }}
        cancelButtonProps={{ disabled: isSubmittingData }}
        destroyOnClose
      >
        <Content>
          <Space direction="vertical">
            <Text style={{ color: "#cf1322" }}>
              By saving the new password, you will be automatically signed out,
              so do remember your new password.
            </Text>
          </Space>
          <Form
            style={{ marginTop: 10 }}
            form={frmSecurity}
            labelCol={{ span: 9, offset: 0 }}
            labelAlign="left"
            onFinish={updateSecurityCallback}
          >
            <Form.Item
              name="current_password"
              style={{ marginBottom: 10 }}
              label="Current Password"
              rules={[
                {
                  required: true,
                  min: 3,
                  max: 20,
                  message:
                    "Current password cannot be blank or less than 3 characters.",
                },
              ]}
              hasFeedback
            >
              <Input.Password minLength={3} maxLength={20} required />
            </Form.Item>
            <Form.Item
              name="new_password"
              style={{ marginBottom: 10 }}
              label="New Password"
              rules={[
                {
                  required: true,
                  min: 3,
                  max: 20,
                  message:
                    "New password cannot be blank or less than 3 characters.",
                },
              ]}
              hasFeedback
            >
              <Input.Password minLength={3} maxLength={20} required />
            </Form.Item>
            <Form.Item
              name="confirm_new_password"
              style={{ marginBottom: 10 }}
              label="Confirm New Password"
              dependencies={["new_password"]}
              rules={[
                {
                  required: true,
                  min: 3,
                  max: 20,
                  message:
                    "Confirm new password cannot be blank or less than 3 characters.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password minLength={3} maxLength={20} required />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <Modal
        title={`Profile`}
        open={isProfileModalOpen}
        afterOpenChange={async (open) => {
          if (open) {
            await loadProfile();
          }
        }}
        afterClose={() => {
          frmProfile.resetFields();
        }}
        onOk={() => {
          frmProfile.submit();
        }}
        okText="Save"
        okButtonProps={{
          disabled: isSubmittingData || isLoadingData,
          loading: isSubmittingData || isLoadingData,
          htmlType: "submit",
        }}
        closable={false}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setIsProfileModalOpen(false);
        }}
        cancelButtonProps={{ disabled: isSubmittingData || isLoadingData }}
        destroyOnClose
      >
        <Content>
          <Form
            disabled={isSubmittingData || isLoadingData}
            style={{ marginTop: 10 }}
            form={frmProfile}
            labelCol={{ span: 9, offset: 0 }}
            labelAlign="left"
            onFinish={updateProfileCallback}
          >
            <Form.Item
              name="display_name"
              style={{ marginBottom: 10 }}
              label="Display Name"
              rules={[
                {
                  type: "string",
                  max: 50,
                  message: "Maximum 50 characters allowed.",
                },
              ]}
              initialValue=""
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="location"
              style={{ marginBottom: 10 }}
              label="Location"
              initialValue=""
            >
              <Select options={officeLocations} />
            </Form.Item>
            <Form.Item
              name="department"
              style={{ marginBottom: 10 }}
              label="Department"
              initialValue=""
            >
              <Select
                options={[
                  {
                    value: "General Management",
                    label: "General Management",
                  },
                  {
                    value: "Marketing",
                    label: "Marketing",
                  },
                  { value: "Operations", label: "Operations" },
                  { value: "Finance", label: "Finance" },
                  { value: "Sales", label: "Sales" },
                  { value: "Human Resource", label: "Human Resource" },
                  { value: "Purchase", label: "Purchase" },
                  { value: "Business", label: "Business" },
                  { value: "Engineering", label: "Engineering" },
                  {
                    value: "Information Technology",
                    label: "Information Technology",
                  },
                  { value: "Legal", label: "Legal" },
                  { value: "Production", label: "Production" },
                  { value: "Risk Management", label: "Risk Management" },
                  { value: "Maintenance", label: "Maintenance" },
                  {
                    value: "Customer Service",
                    label: "Customer Service",
                  },
                  {
                    value: "Transportation",
                    label: "Transportation",
                  },
                  {
                    value: "Other",
                    label: "Other",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              initialValue=""
              name="email"
              style={{ marginBottom: 10 }}
              label="Email"
              required
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 5,
                  max: 100,
                  message: "Minimum 5 and maximum 100 characters allowed.",
                },
              ]}
            >
              <Input required minLength={5} maxLength={100} showCount />
            </Form.Item>
            <Form.Item
              initialValue=""
              name="mobile"
              style={{ marginBottom: 10 }}
              label="Phone"
              rules={[
                {
                  type: "string",
                  max: 50,
                  message: "Maximum 50 characters allowed.",
                },
              ]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <div className="header-nav">
        <ul>
          <li>
            <CompanyLogo />
          </li>
          {["1000", "2000", "8000", "9000"].includes(roles) && (
            <li
              className={current_pathname === "/" ? "active-header-nav" : ""}
              onClick={() => {
                navigate("/");
              }}
            >
              Overview
            </li>
          )}
          {["1000", "2000", "8000", "9000"].includes(roles) && (
            <li
              className={
                current_pathname === "/transactions" ? "active-header-nav" : ""
              }
              onClick={() => {
                navigate("/transactions");
              }}
            >
              Transactions
            </li>
          )}
          {["2000", "8000", "9000"].includes(roles) && (
            <li
              className={
                current_pathname === "/customers" ? "active-header-nav" : ""
              }
              onClick={() => {
                navigate("/customers");
              }}
            >
              Customers
            </li>
          )}
          {["2000", "8000", "9000"].includes(roles) && (
            <li
              className={
                current_pathname === "/cards" ? "active-header-nav" : ""
              }
              onClick={() => {
                navigate("/cards");
              }}
            >
              Cards
            </li>
          )}
          {["1000", "2000", "8000", "9000"].includes(roles) && (
            <li
              className={`link ${
                (current_pathname.includes("gauges") ||
                  current_pathname.includes("delivery-history") ||
                  current_pathname.includes("inventory-history") ||
                  current_pathname.includes("load-history")) &&
                !current_pathname.includes("admin")
                  ? "dropdown active-header-nav"
                  : "dropdown"
              }`}
            >
              Inventory
              <div className="dropdown-menu">
                <Link
                  to={"/gauges"}
                  className={
                    current_pathname === "/gauges"
                      ? "dropdown-link active-menu"
                      : "dropdown-link"
                  }
                >
                  Tank Readings
                </Link>
                <Link
                  to={"/inventory-history"}
                  className={
                    current_pathname === "/inventory-history"
                      ? "dropdown-link active-menu"
                      : "dropdown-link"
                  }
                >
                  Tank History
                </Link>
                <Link
                  to={"/delivery-history"}
                  className={
                    current_pathname === "/delivery-history"
                      ? "dropdown-link active-menu"
                      : "dropdown-link"
                  }
                >
                  Delivery History
                </Link>
                <Link
                  to={"/load-history"}
                  className={
                    current_pathname === "/load-history"
                      ? "dropdown-link active-menu"
                      : "dropdown-link"
                  }
                >
                  Transaction History
                </Link>
              </div>
            </li>
          )}
          {["1000", "2000", "8000", "9000"].includes(roles) && (
            <li
              className={`link ${
                current_pathname.includes("totalizers") ||
                current_pathname.includes("custom-logics") ||
                current_pathname.includes("rcu-dittos") ||
                current_pathname.includes("bay-monitor") ||
                current_pathname.includes("logs")
                  ? "dropdown active-header-nav"
                  : "dropdown"
              }`}
            >
              Utilities
              <div className="dropdown-menu">
                <Link
                  to={"/totalizers"}
                  className={
                    current_pathname === "/totalizers"
                      ? "dropdown-link active-menu"
                      : "dropdown-link"
                  }
                >
                  Totalizer
                </Link>
                <Link
                  to={"/custom-logics"}
                  className={
                    current_pathname === "/custom-logics"
                      ? "dropdown-link active-menu"
                      : "dropdown-link"
                  }
                >
                  Custom Logics
                </Link>
                {["2000", "8000", "9000"].includes(roles) && (
                  <Link
                    to={"/rcu-dittos"}
                    className={
                      current_pathname === "/rcu-dittos"
                        ? "dropdown-link active-menu"
                        : "dropdown-link"
                    }
                  >
                    RCU Dittos
                  </Link>
                )}
                {["2000", "8000", "9000"].includes(roles) && (
                  <Link
                    to={"/bay-monitor"}
                    className={
                      current_pathname === "/bay-monitor"
                        ? "dropdown-link active-menu"
                        : "dropdown-link"
                    }
                  >
                    Bay Monitor
                  </Link>
                )}
                {["2000", "8000", "9000"].includes(roles) && (
                  <Link
                    to={"/logs"}
                    className={
                      current_pathname === "/logs"
                        ? "dropdown-link active-menu"
                        : "dropdown-link"
                    }
                  >
                    MSG/Audit Logs
                  </Link>
                )}
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="nav-profile">
        {["1000", "2000", "8000", "9000"].includes(roles) && (
          <Dropdown
            menu={{
              items: [
                {
                  key: "nav-profile-basic",
                  label: (
                    <NavLink
                      onClick={() => {
                        setIsProfileModalOpen(true);
                      }}
                    >
                      Profile
                    </NavLink>
                  ),
                },
                {
                  key: "nav-profile-security",
                  label: (
                    <NavLink
                      onClick={() => {
                        setIsSecurityModalOpen(true);
                      }}
                    >
                      Security
                    </NavLink>
                  ),
                },
                {
                  key: "nav-profile-admin",
                  label: (
                    <NavLink
                      to={"/admin"}
                      hidden={!["8000", "9000"].includes(roles)}
                    >
                      Admin
                    </NavLink>
                  ),
                },
                { type: "divider" },
                {
                  key: "nav-profile-logout",
                  label: <NavLink onClick={signOut}>Logout</NavLink>,
                },
              ],
            }}
          >
            <Avatar size="default" icon={<UserOutlined />} />
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default SiteHeader;
