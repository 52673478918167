import React from "react";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import useAuth from "../context/hooks/useAuth";
const { Text } = Typography;

const AdminProfileNav = () => {
  const { auth } = useAuth();

  let parsedCookies = {};
  if (auth.accessToken) {
    parsedCookies = jwt_decode(auth.accessToken);
  }
  const { roles } = parsedCookies.UserInfo;
  return (
    <div className="admin-profile-navigation">
      <Text level={4} style={{ color: "#8c8c8c", fontSize: 18 }}>
        Your Profile
      </Text>
      <ol>
        <li>
          <Link to="/admin">Business Information</Link>
        </li>
        <li>
          <Link to="/admin/office-location">Offlice Location</Link>
        </li>
        <li>
          <Link to="/admin/settings" hidden={!["9000"].includes(roles)}>
            Application Settings
          </Link>
        </li>
        <li>
          <Link to="/admin/smtp-setting" hidden={!["9000"].includes(roles)}>
            SMTP Settings
          </Link>
        </li>
      </ol>
    </div>
  );
};

export default AdminProfileNav;
