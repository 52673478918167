import React from "react";
import { Link } from "react-router-dom";
import { Layout, Typography } from "antd";
const { Content } = Layout;
const { Text, Title } = Typography;

const Page404 = () => {
  return (
    <Layout className="layout-404">
      <Content className="middle-404">
        <Content className="title-404">
          <Title
            style={{ color: "#cf1322", fontWeight: "bolder", fontSize: 80 }}
          >
            404
          </Title>
          <Text style={{ color: "#bfbfbf", fontWeight: 450, fontSize: 18 }}>
            We are sorry but the page you are looking for was not found..
          </Text>
        </Content>
        <Content className="footer-404">
          <Link to={"/"} style={{ fontWeight: "bold", color: "#1d39c4" }}>
            Go back to Dashboard
          </Link>
        </Content>
      </Content>
    </Layout>
  );
};

export default Page404;
