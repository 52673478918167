import React from "react";
import { Link } from "react-router-dom";
import { Layout, Typography } from "antd";
const { Content } = Layout;
const { Text, Title } = Typography;

const Page403 = () => {
  return (
    <Layout className="layout-404">
      <Content className="middle-404">
        <Content className="title-404">
          <Title
            style={{ color: "#08979c", fontWeight: "bolder", fontSize: 80 }}
          >
            403
          </Title>
          <Text style={{ color: "#bfbfbf", fontWeight: 450, fontSize: 18 }}>
            We are sorry but you do not have permission to access this page..
          </Text>
        </Content>
        <Content className="footer-404">
          <Link to={"/"} style={{ fontWeight: "bold", color: "#1d39c4" }}>
            Go back to Dashboard
          </Link>
        </Content>
      </Content>
    </Layout>
  );
};

export default Page403;
