import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import Loader from "../../Components/Loader";
import {
  LuClipboardEdit,
  LuFuel,
  LuWrapText,
  LuTrash2,
  LuFileBox,
  LuDownloadCloud,
  LuUpload,
  LuTable,
  LuArrowDownCircle,
  LuNetwork,
} from "react-icons/lu";
import useAxiosPrivate from "../../context/hooks/useAxiosPrivate";
import {
  Layout,
  Form,
  Table,
  Button,
  Input,
  InputNumber,
  Checkbox,
  Modal,
  Select,
  Space,
  Typography,
  Tooltip,
  Radio,
  notification,
  Dropdown,
} from "antd";
import isNumber from "isnumber";
import { AxiosError } from "axios";
const { Content } = Layout;
const { Search } = Input;
const { Text } = Typography;
const AdminMetres = () => {
  const initCheck = useRef(true);
  const [hasInitialised, setHasInitialised] = useState(false);
  const [meters, setMeters] = useState([]);
  const [metre, setMetre] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMetreInfo, setCurrentMetreInfo] = useState(null);
  const [isDownloadMetreParamsOpen, setIsDownloadMetreParamsOpen] =
    useState(false);
  const [isSetupTotalizerModalOpen, setIsSetupTotalizerModalOpen] =
    useState(false);
  const [isSettingupTotalizer, setIsSettingUpTotalizer] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formAction, setFormAction] = useState("add");
  const [searchKey, setSearchKey] = useState("");
  const [submitSearchKey, setSubmitSearchKey] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [currentMetreId, setCurrentMetreId] = useState(null);
  const [isUploadingLocalPrompts, setIsUploadingLocalPrompts] = useState(false);
  const [isMeterLoading, setIsMeterLoading] = useState(false);
  const [isDownloadingInProgress, setIsDownloadingInProgress] = useState(false);
  const [isDownloadCardOptionChecked, setDownloadCardOptionChecked] =
    useState(false);
  const [frmMetre] = Form.useForm();
  const [frmDownloadMetreParams] = Form.useForm();
  const axiosPrivate = useAxiosPrivate();

  const [modal, contextHolderConfirm] = Modal.useModal();

  const columns = [
    {
      title: "Display Name",
      dataIndex: "display_name",
      align: "left",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        return a.display_name > b.display_name;
      },
    },
    {
      title: "Connector",
      dataIndex: "description",
      width: 300,
      align: "center",
    },
    {
      title: "IP",
      dataIndex: "ip",
      width: 140,
      align: "center",
      responsive: ["xl"],
    },
    {
      title: "Mode",
      dataIndex: "remote_mode",
      width: 120,
      align: "center",
      render: (text, record, index) => {
        return isNumber(text) && Number(text) === 1 ? "Remote" : "Standalone";
      },
    },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 295,
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Link to={`/admin/metres/${record.metre_id}/presets`}>
              <Tooltip title="Presets">
                <LuFileBox style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Link to={`/admin/metres/${record.metre_id}/products`}>
              <Tooltip title="Products">
                <LuFuel style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Link to={`/admin/metres/${record.metre_id}/logics`}>
              <Tooltip title="Custom Logics">
                <LuWrapText style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Link
              onClick={() => {
                setCurrentMetreInfo(record);
                setIsSetupTotalizerModalOpen(true);
              }}
            >
              <Tooltip title="Setup Totolizer Logics">
                <LuTable style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Link
              onClick={async () => {
                try {
                  setIsUploadingLocalPrompts(true);
                  const { data } = await axiosPrivate.get(
                    `/admin/meters/${record.metre_id}/upload-local-prompts`
                  );
                  const { success, message } = data;
                  if (success) {
                    notification.success({
                      message: `Successfully uploaded local prompts.`,
                      placement: "bottom",
                      duration: 4,
                    });
                  } else {
                    notification.error({
                      message: message,
                      placement: "bottom",
                      duration: 4,
                    });
                  }
                } catch (err) {
                  // console.error(err);
                  notification.error({
                    message:
                      err instanceof AxiosError
                        ? err.response.data
                        : err?.message ?? err?.toString(),
                    placement: "bottom",
                    duration: 4,
                  });
                } finally {
                  setIsUploadingLocalPrompts(false);
                }
              }}
            >
              <Tooltip title="Upload Local Prompts">
                <LuUpload style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Link
              onClick={() => {
                setCurrentMetreInfo(record);
                frmDownloadMetreParams.resetFields();
                setIsDownloadMetreParamsOpen(true);
                setDownloadCardOptionChecked(false);
              }}
            >
              <Tooltip title="Download Configuration">
                <LuDownloadCloud style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "mnuSetMetreRemoteMode",
                    label: "Remote Mode",
                    icon: <LuNetwork />,
                    onClick: async () => {
                      try {
                        setIsUploadingLocalPrompts(true);
                        const { data } = await axiosPrivate.get(
                          `/admin/meters/${record.metre_id}/set-remote-mode`
                        );
                        notification.success({
                          message: `Successfully set metre to run in Remote Mode.`,
                          placement: "bottom",
                          duration: 4,
                        });
                      } catch (err) {
                        notification.error({
                          message:
                            err instanceof AxiosError
                              ? err.response.data
                              : err?.message ?? err?.toString(),
                          placement: "bottom",
                          duration: 4,
                        });
                      } finally {
                        setIsUploadingLocalPrompts(false);
                      }
                    },
                  },
                  {
                    key: "mnuSetMetreStandaloneMode",
                    label: "Standalone Mode",
                    onClick: async () => {
                      try {
                        setIsUploadingLocalPrompts(true);
                        const { data } = await axiosPrivate.get(
                          `/admin/meters/${record.metre_id}/set-standalone-mode`
                        );
                        notification.success({
                          message: `Successfully set metre to run in Standalone Mode.`,
                          placement: "bottom",
                          duration: 4,
                        });
                      } catch (err) {
                        notification.error({
                          message:
                            err instanceof AxiosError
                              ? err.response.data
                              : err?.message ?? err?.toString(),
                          placement: "bottom",
                          duration: 4,
                        });
                      } finally {
                        setIsUploadingLocalPrompts(false);
                      }
                    },
                  },
                ],
              }}
            >
              <LuArrowDownCircle
                style={{ cursor: "pointer", fontSize: 15, color: "#4096ff" }}
              />
            </Dropdown>
            <Link
              onClick={() => {
                handlerShowMetre(record.metre_id);
              }}
            >
              <Tooltip title="Edit">
                <LuClipboardEdit style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Link
              onClick={() => {
                handlerDeleteMetre(record.metre_id);
              }}
            >
              <Tooltip title="Delete">
                <LuTrash2 style={{ fontSize: 15, color: "#cf1322" }} />
              </Tooltip>
            </Link>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const timeOutId = setTimeout(() => setSubmitSearchKey(searchKey), 500);
    return () => clearTimeout(timeOutId);
  }, [searchKey]);

  useEffect(() => {
    if (initCheck.current || hasInitialised) {
      initCheck.current = false;
      (async () => {
        try {
          setIsMeterLoading(true);
          await loadConnectors();
          await loadMetres();
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setIsMeterLoading(false);
          setHasInitialised(true);
        }
      })();
    }
  }, [submitSearchKey, searchLocation]);

  const loadConnectors = async () => {
    try {
      const res_connectors = await axiosPrivate.get(`/admin//connectors?q=`);
      const connectors = res_connectors.data.map((c) => {
        return { value: c.connector_id, label: c.description };
      });
      setConnectors(connectors);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    }
  };

  const loadMetres = async () => {
    try {
      const { data } = await axiosPrivate.get(
        `/admin/meters?q=${encodeURIComponent(
          searchKey
        )}&l=${encodeURIComponent(searchLocation)}`
      );
      const { res_metres } = data;
      setMeters(res_metres);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    }
  };

  const handlerSearchChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handlerSearchLocationChanged = (connector_id) => {
    setSearchLocation(connector_id);
  };

  const handlerShowMetreForm = () => {
    setFormAction("add");
    setIsModalOpen(true);
  };

  const handleOk = async (close) => {
    frmMetre.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlerShowMetre = async (metre_id) => {
    try {
      setIsMeterLoading(true);
      const res_metre = await axiosPrivate.get(`/admin/meter/${metre_id}`);
      setMetre(res_metre.data);
      setFormAction("edit");
      setCurrentMetreId(metre_id);
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsMeterLoading(false);
    }
  };

  const handlerDeleteMetre = async (metre_id) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete this metre?",
      onOk: async () => {
        try {
          setSubmitting(true);
          await axiosPrivate.delete(`/admin/meter/${metre_id}`);
          notification.success({
            message: "Successfully deleted the metre.",
            placement: "bottom",
            duration: 4,
          });
          setMeters((state) => {
            const new_state = state.filter((m) => m.metre_id !== metre_id);
            return [...new_state];
          });
          setIsModalOpen(false);
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });
  };

  const handleMetreFormSubmit = async (data) => {
    try {
      setSubmitting(true);
      if (formAction === "add") {
        const res_add_metre = await axiosPrivate.post("/admin/meters", data);
        notification.success({
          message: "Successfully created new meter.",
          placement: "bottom",
          duration: 4,
        });
        setMeters((state) => {
          return [...state, { ...res_add_metre.data }];
        });
      } else if (formAction === "edit") {
        const res_update_metre = await axiosPrivate.patch(
          `/admin/meter/${currentMetreId}`,
          { ...data }
        );
        notification.success({
          message: `Successfully updated meter ${data.display_name}.`,
          placement: "bottom",
          duration: 4,
        });
        setMeters((state) => {
          let found_metre_index = state.findIndex(
            (metre) => metre.metre_id === currentMetreId
          );
          state[found_metre_index] = {
            ...state[found_metre_index],
            ...res_update_metre.data,
          };
          return [...state];
        });
      }
      setIsModalOpen(false);
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err.response.data
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const setupTotalizerLogics = async (metreInfo) => {
    try {
      setIsSettingUpTotalizer(true);
      const res_download_products = await axiosPrivate.get(
        `/admin/meters/${metreInfo.metre_id}/setup-totalizer-logics`
      );
      const { success } = res_download_products.data;
      if (success) {
        notification.success({
          message: "Successfully setup totalizer logics.",
          placement: "bottom",
          duration: 4,
        });
        setIsSetupTotalizerModalOpen(false);
      } else {
        notification.error({
          message: "Something went wrong, please try again after 2-5 minutes.",
          placement: "bottom",
          duration: 4,
        });
      }
    } catch (err) {
      notification.error({
        message:
          err instanceof AxiosError
            ? err.response.data
            : err?.message ?? err?.toString(),
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsSettingUpTotalizer(false);
    }
  };

  const modalMetreParamOkCallback = () => {
    frmDownloadMetreParams.submit();
  };

  const modalMetreParamCancelCallback = () => {
    if (isDownloadingInProgress) {
      return;
    }
    setIsDownloadMetreParamsOpen(false);
  };

  const downloadMetreParamCallback = async (data) => {
    const { download_content, keep_extra_info } = data;
    if (download_content === "preset_product_card") {
      try {
        setIsDownloadingInProgress(true);
        const res_download_products = await axiosPrivate.get(
          `/admin/meters/${
            currentMetreInfo.metre_id
          }/download-metre-settings?keep_extra_info=${encodeURIComponent(
            keep_extra_info
          )}`
        );
        const { success } = res_download_products.data;
        if (success) {
          notification.success({
            message: "Successfully downloaded metre settings.",
            placement: "bottom",
            duration: 4,
          });
        } else {
          notification.error({
            message:
              "Something went wrong, please try again after 2-5 minutes.",
            placement: "bottom",
            duration: 4,
          });
        }
      } catch (err) {
        notification.error({
          message:
            err instanceof AxiosError
              ? err.response.data
              : err?.message ?? err?.toString(),
          placement: "bottom",
          duration: 4,
        });
      } finally {
        setIsDownloadingInProgress(false);
      }
    } else if (download_content === "custom_logic") {
      try {
        setIsDownloadingInProgress(true);
        const { data } = await axiosPrivate.post(
          `/admin/meters/${currentMetreInfo.metre_id}/download-custom-logics`,
          { numberOfLogicsForDownload: 100 }
        );
        const { totalNumberOfLogics } = data;
        notification.success({
          message: `Successfully downloaded ${totalNumberOfLogics} custom logics.`,
          placement: "bottom",
          duration: 4,
        });
      } catch (err) {
        notification.error({
          message:
            err instanceof AxiosError
              ? err.response.data
              : err?.message ?? err?.toString(),
          placement: "bottom",
          duration: 4,
        });
      } finally {
        setIsDownloadingInProgress(false);
      }
    } else if (download_content === "local_prompts") {
      try {
        setIsDownloadingInProgress(true);
        const { data } = await axiosPrivate.get(
          `/admin/meters/${currentMetreInfo.metre_id}/download-local-prompts`
        );
        const { success, message } = data;
        if (success) {
          notification.success({
            message: `Successfully downloaded local prompts.`,
            placement: "bottom",
            duration: 4,
          });
        } else {
          notification.error({
            message: message,
            placement: "bottom",
            duration: 4,
          });
        }
      } catch (err) {
        notification.error({
          message:
            err instanceof AxiosError
              ? err.response.data
              : err?.message ?? err?.toString(),
          placement: "bottom",
          duration: 4,
        });
      } finally {
        setIsDownloadingInProgress(false);
      }
    }
    setIsDownloadMetreParamsOpen(false);
  };

  return (
    <Content
      style={{
        display: "flex",
        flexFlow: "column",
        overflow: "hidden",
        gap: 5,
      }}
    >
      <Loader loading={isUploadingLocalPrompts} />
      {contextHolderConfirm}
      <Modal
        title={
          formAction === "add"
            ? "Add New Multiloader Meter"
            : "Edit Multiloader Meter"
        }
        open={isModalOpen}
        onOk={handleOk}
        okText="Save"
        okButtonProps={{
          disabled: submitting,
          loading: submitting,
          htmlType: "submit",
        }}
        afterOpenChange={(open) => {
          if (open && formAction === "edit") {
            const {
              display_name,
              connector_id,
              ip,
              port,
              web_port,
              host_hex_key,
              rcu_address,
              init_year,
              init_bol_from,
              init_bol_till,
              init_bring_all_bols,
              login_id,
              login_password,
              remote_prompt_1,
              remote_prompt_2,
              remote_prompt_3,
              remote_prompt_4,
              remote_prompt_5,
              remote_prompt_mandatory_1,
              remote_prompt_mandatory_2,
              remote_prompt_mandatory_3,
              remote_prompt_mandatory_4,
              remote_prompt_mandatory_5,
              local_prompt_1,
              local_prompt_2,
              local_prompt_3,
              local_prompt_4,
              local_prompt_1_input_length,
              local_prompt_2_input_length,
              local_prompt_3_input_length,
              local_prompt_4_input_length,
              use_800_function,
              disabled,
              remote_mode,
              enable_authorisation_logic,
              fueling_activity_record_enabled,
            } = metre;
            frmMetre.setFieldsValue({
              display_name,
              connector_id,
              ip,
              port,
              web_port,
              host_hex_key,
              rcu_address,
              init_year,
              init_bol_from,
              init_bol_till,
              login_id,
              login_password,
              remote_prompt_1,
              remote_prompt_2,
              remote_prompt_3,
              remote_prompt_4,
              remote_prompt_5,
              remote_prompt_mandatory_1,
              remote_prompt_mandatory_2,
              remote_prompt_mandatory_3,
              remote_prompt_mandatory_4,
              remote_prompt_mandatory_5,
              local_prompt_1_input_length,
              local_prompt_2_input_length,
              local_prompt_3_input_length,
              local_prompt_4_input_length,
              local_prompt_1,
              local_prompt_2,
              local_prompt_3,
              local_prompt_4,
              init_bring_all_bols: init_bring_all_bols ? true : false,
              use_800_function: use_800_function ? true : false,
              disabled: disabled ? true : false,
              remote_mode: remote_mode ? true : false,
              enable_authorisation_logic: enable_authorisation_logic
                ? true
                : false,
              fueling_activity_record_enabled,
            });
          }
        }}
        afterClose={() => {
          frmMetre.resetFields();
        }}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: submitting }}
        destroyOnClose
        styles={{ body: { overflow: "scroll", height: 500 } }}
      >
        <Content>
          <Form
            labelCol={{ span: 8, offset: 0 }}
            labelAlign="left"
            labelWrap
            requiredMark
            layout="vertical"
            form={frmMetre}
            onFinish={handleMetreFormSubmit}
            disabled={submitting}
          >
            <Form.Item
              name="display_name"
              label="Display Name"
              required
              rules={[{ required: true, max: 50, min: 3, message: "" }]}
              style={{ marginBottom: 10 }}
            >
              <Input minLength={3} maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="connector_id"
              label="Connector"
              required
              rules={[{ required: true, message: "" }]}
              style={{ marginBottom: 10 }}
            >
              <Select options={connectors}></Select>
            </Form.Item>
            <Form.Item
              required
              name="rcu_address"
              label="RCU Address"
              rules={[
                {
                  required: true,
                  max: 99,
                  min: 1,
                  message: "",
                  type: "number",
                },
              ]}
              initialValue={1}
              style={{ marginBottom: 10 }}
            >
              <InputNumber max={99} min={1} />
            </Form.Item>
            <Form.Item
              name="ip"
              label="Metre IP"
              rules={[{ required: true, max: 20, message: "", type: "string" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} />
            </Form.Item>
            <Form.Item
              required
              name="port"
              label="Meter Port"
              rules={[
                {
                  required: true,
                  max: 65535,
                  min: 80,
                  message: "",
                  type: "number",
                },
              ]}
              initialValue={7743}
              style={{ marginBottom: 10 }}
            >
              <InputNumber max={65535} min={80} />
            </Form.Item>
            <Form.Item
              required
              name="web_port"
              label="Web Interface Port"
              rules={[
                {
                  required: true,
                  max: 65535,
                  min: 80,
                  message: "",
                  type: "number",
                },
              ]}
              initialValue={80}
              style={{ marginBottom: 10 }}
            >
              <InputNumber max={65535} min={80} />
            </Form.Item>
            <Form.Item
              name="host_hex_key"
              label="Host Key"
              required
              rules={[
                {
                  required: true,
                  min: 10,
                  max: 10,
                  message: "",
                  type: "string",
                },
              ]}
              style={{ marginBottom: 10 }}
              tooltip={"Multiload Encryption Key"}
              initialValue={"0x00000000"}
            >
              <Input minLength={10} maxLength={10} showCount required />
            </Form.Item>
            <Form.Item
              name="login_id"
              label="Login ID"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="login_password"
              label="Login Password"
              rules={[{ max: 50, message: "" }]}
              style={{ marginBottom: 10 }}
            >
              <Input.Password maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              name="init_year"
              required
              label="Init. Year"
              rules={[
                { required: true, max: 2050, message: "", type: "number" },
              ]}
              initialValue={2000}
              style={{ marginBottom: 10 }}
            >
              <InputNumber min={2000} max={2050} />
            </Form.Item>
            <Form.Item
              name="init_bol_from"
              required
              label="Init. Bol# From"
              rules={[
                {
                  required: true,
                  max: 9999,
                  min: 1,
                  message: "",
                  type: "number",
                },
              ]}
              initialValue={1}
              style={{ marginBottom: 10 }}
            >
              <InputNumber min={1} max={9999} />
            </Form.Item>
            <Form.Item
              name="init_bol_till"
              required
              label="Init. Bol# Till"
              initialValue={1}
              rules={[
                {
                  required: true,
                  max: 9999,
                  min: 1,
                  message: "",
                  type: "number",
                },
              ]}
              style={{ marginBottom: 10 }}
            >
              <InputNumber min={1} max={9999} />
            </Form.Item>
            <Form.Item
              name="remote_prompt_1"
              label="Remote Prompt 1"
              required
              rules={[{ required: true, max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="remote_prompt_mandatory_1"
              required
              style={{ marginBottom: 10 }}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox defaultChecked={false}>
                Remote Prompt 1 Mandatory
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="remote_prompt_2"
              label="Remote Prompt 2"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="remote_prompt_mandatory_2"
              required
              style={{ marginBottom: 10 }}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox defaultChecked={false}>
                Remote Prompt 2 Mandatory
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="remote_prompt_3"
              label="Remote Prompt 3"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="remote_prompt_mandatory_3"
              required
              style={{ marginBottom: 10 }}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox defaultChecked={false}>
                Remote Prompt 3 Mandatory
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="remote_prompt_4"
              label="Remote Prompt 4"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="remote_prompt_mandatory_4"
              required
              style={{ marginBottom: 10 }}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox defaultChecked={false}>
                Remote Prompt 4 Mandatory
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="remote_prompt_5"
              label="Remote Prompt 5"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="remote_prompt_mandatory_5"
              required
              style={{ marginBottom: 10 }}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox defaultChecked={false}>
                Remote Prompt 4 Mandatory
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="local_prompt_1"
              label="Local Prompt 1"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="local_prompt_1_input_length"
              label="Input Length 1"
              rules={[
                {
                  required: true,
                  min: 0,
                  max: 19,
                  message: "",
                  type: "number",
                },
              ]}
              initialValue={10}
              style={{ marginBottom: 10 }}
            >
              <InputNumber min={0} max={19} />
            </Form.Item>
            <Form.Item
              name="local_prompt_2"
              label="Local Prompt 2"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="local_prompt_2_input_length"
              label="Input Length 2"
              rules={[
                {
                  required: true,
                  min: 0,
                  max: 19,
                  message: "",
                  type: "number",
                },
              ]}
              initialValue={10}
              style={{ marginBottom: 10 }}
            >
              <InputNumber min={0} max={19} />
            </Form.Item>
            <Form.Item
              name="local_prompt_3"
              label="Local Prompt 3"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="local_prompt_3_input_length"
              label="Input Length 3"
              rules={[
                {
                  required: true,
                  min: 0,
                  max: 19,
                  message: "",
                  type: "number",
                },
              ]}
              initialValue={10}
              style={{ marginBottom: 10 }}
            >
              <InputNumber min={0} max={19} />
            </Form.Item>
            <Form.Item
              name="local_prompt_4"
              label="Local Prompt 4"
              rules={[{ max: 20, message: "" }]}
              style={{ marginBottom: 10 }}
              initialValue={""}
            >
              <Input maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              name="local_prompt_4_input_length"
              label="Input Length 4"
              rules={[
                {
                  required: true,
                  min: 0,
                  max: 19,
                  message: "",
                  type: "number",
                },
              ]}
              initialValue={10}
              style={{ marginBottom: 10 }}
            >
              <InputNumber min={0} max={19} />
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              initialValue={false}
              name="init_bring_all_bols"
              style={{ marginBottom: 10 }}
            >
              <Checkbox>Bring All Stored BOLs</Checkbox>
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              initialValue={false}
              name="use_800_function"
              style={{ marginBottom: 10 }}
            >
              <Checkbox>Use Early Version Function</Checkbox>
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              initialValue={false}
              name="remote_mode"
              style={{ marginBottom: 10 }}
            >
              <Checkbox>Remote Mode</Checkbox>
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              initialValue={false}
              name="enable_authorisation_logic"
              style={{ marginBottom: 10 }}
            >
              <Checkbox>Auth Business Logic</Checkbox>
            </Form.Item>
            <Form.Item
              name="fueling_activity_record_enabled"
              required
              style={{ marginBottom: 10 }}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox defaultChecked={false}>
                Record Fueling Activity
              </Checkbox>
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              initialValue={false}
              name="disabled"
              style={{ marginBottom: 10 }}
            >
              <Checkbox>Disabled</Checkbox>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <Modal
        title="Download Metre Params"
        open={isDownloadMetreParamsOpen}
        onOk={modalMetreParamOkCallback}
        okText="Download"
        okButtonProps={{
          disabled: isDownloadingInProgress,
          loading: isDownloadingInProgress,
          htmlType: "submit",
        }}
        closable={false}
        maskClosable={false}
        keyboard={false}
        onCancel={modalMetreParamCancelCallback}
        cancelButtonProps={{ disabled: isDownloadingInProgress }}
        destroyOnClose
      >
        <Content>
          <Text style={{ color: "#cf1322" }}>
            Download settings from metre {currentMetreInfo?.display_name} will
            overwrite existing product, preset, card definitions (NOT FOR
            UNIFIED CARD MANAGEMENT) and custom logics in the cloud database.
            Are you sure to continue? (This can take some time, so be patient
            and do not close the window!)
          </Text>
          <Form
            form={frmDownloadMetreParams}
            style={{ marginTop: "20px" }}
            labelCol={{ span: 8, offset: 0 }}
            labelAlign="left"
            labelWrap
            requiredMark
            layout="horizontal"
            disabled={isDownloadingInProgress}
            onFinish={downloadMetreParamCallback}
          >
            <Form.Item
              name="download_content"
              style={{ marginBottom: 10 }}
              rules={[
                {
                  required: true,
                  message: "You have to choose one option to download.",
                },
              ]}
              initialValue={"preset_product_card"}
              required
            >
              <Radio.Group
                buttonStyle="outline"
                defaultValue={"preset_product_card"}
                onChange={(e) => {
                  setDownloadCardOptionChecked(
                    e.target.value === "preset_product_card" ? false : true
                  );
                }}
              >
                <Space direction="vertical">
                  <Radio defaultChecked={false} value="preset_product_card">
                    Presets, Metres, Components, Products and Cards
                  </Radio>
                  <Radio defaultChecked={false} value="custom_logic">
                    Custom Logics (Only download the first 100 logics)
                  </Radio>
                  <Radio defaultChecked={false} value="local_prompts">
                    Local Prompts
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="keep_extra_info"
              style={{ marginBottom: 10 }}
              required
              valuePropName="checked"
              hidden={isDownloadCardOptionChecked}
              initialValue={false}
            >
              <Checkbox>Keep extra info for Preset, Metre and Card</Checkbox>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <Modal
        title={`Setup totalizer logics for ${currentMetreInfo?.display_name}`}
        open={isSetupTotalizerModalOpen}
        onOk={() => {
          setupTotalizerLogics(currentMetreInfo);
        }}
        okText="Upload"
        okButtonProps={{
          disabled: isSettingupTotalizer,
          loading: isSettingupTotalizer,
          htmlType: "submit",
        }}
        closable={false}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setIsSetupTotalizerModalOpen(false);
        }}
        cancelButtonProps={{ disabled: isSettingupTotalizer }}
        destroyOnClose
      >
        <Content>
          <Text style={{ color: "#cf1322" }}>
            Setup totalizers will overwrite existing custom logics from index
            101 on the metre. Are you sure to continue? (This can take some
            time, so be patient and do not close the window!)
          </Text>
        </Content>
      </Modal>
      <div className="admin-metre-toolbar">
        <div>
          <Space>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={async () => {
                try {
                  setIsMeterLoading(true);
                  await loadMetres();
                } catch (err) {
                } finally {
                  setIsMeterLoading(false);
                }
              }}
            />
            <Button
              size="small"
              onClick={() => {
                frmMetre.resetFields();
                handlerShowMetreForm();
              }}
              disabled={isMeterLoading}
            >
              Add Meter
            </Button>
          </Space>
        </div>
        <div>
          <Space>
            <Select
              disabled={isMeterLoading}
              size="small"
              style={{ width: 200 }}
              defaultValue=""
              onChange={handlerSearchLocationChanged}
              options={[{ label: "All Connectors", value: "" }, ...connectors]}
            />
            <Search
              disabled={isMeterLoading}
              size="small"
              placeholder="Key in to search"
              style={{ width: 300 }}
              onChange={handlerSearchChange}
            />
          </Space>
        </div>
      </div>
      <div className="admin-table-wrap">
        <Table
          sticky
          pagination={{ position: ["none", "none"], defaultPageSize: 100 }}
          bordered
          columns={columns}
          rowKey={(metre) => metre.metre_id}
          dataSource={meters}
          loading={isMeterLoading}
          showSorterTooltip={false}
          rowClassName={(record) => record.disabled && "disabled-record"}
        />
      </div>
    </Content>
  );
};

export default AdminMetres;
