import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../context/hooks/useAxiosPrivate";
import AdminProfileNav from "../../Components/ProfileNav";
import { baseUrl } from "../../api/axios";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Layout,
  Form,
  Card,
  Button,
  Input,
  Select,
  Typography,
  Spin,
  notification,
  Upload,
  Image,
  message,
} from "antd";
const { Content } = Layout;
const { Text } = Typography;

const Profile = () => {
  const [frmProfile] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logoPath, setLogoPath] = useState(null);
  const [uploading, setUploading] = useState(false);
  const formRef = useRef();
  const axiosPrivate = useAxiosPrivate();

  const initCheck = useRef(true);

  useEffect(() => {
    const loadProfile = async () => {
      try {
        setIsLoading(true);
        const res_profile = await axiosPrivate.get(`/admin`);
        const {
          full_path,
          industry,
          gst_hst_registration_number,
          company_name,
          address,
          post_code,
          city,
          province,
          country,
          email,
          phone,
        } = res_profile.data;
        setLogoPath(full_path);
        frmProfile.setFieldsValue({
          industry,
          gst_hst_registration_number,
          company_name,
          address,
          post_code,
          city,
          province,
          country,
          email,
          phone,
        });
      } catch (err) {
        notification.error({
          message: err.message,
          placement: "bottom",
          duration: 4,
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (initCheck.current) {
      initCheck.current = false;
      (async () => {
        await loadProfile();
      })();
    }
  }, []);

  const handleSave = async (data) => {
    console.log(data);
    try {
      setSubmitting(true);
      const res_save_profile = await axiosPrivate.patch(`/admin`, data);
      notification.success({
        message: "Successfully saved profile.",
        placement: "bottom",
        duration: 4,
      });
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Content className="admin-profile-content">
      <AdminProfileNav />
      <div className="admin-profile-main">
        <Text style={{ fontSize: 28, color: "#434343" }}>
          Profile - Business Information
        </Text>
        <Card
          size="small"
          title="Company Information"
          style={{
            marginTop: 20,
            width: "100%",
            display: "flex",
            flexFlow: "column",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <Spin spinning={isLoading}>
            <Form
              form={frmProfile}
              style={{ margin: 20 }}
              labelCol={{ span: 6, offset: 0 }}
              wrapperCol={{ span: 10, offset: 0 }}
              labelAlign="left"
              labelWrap
              requiredMark
              ref={formRef}
              layout="horizontal"
              onFinish={handleSave}
              disabled={submitting}
            >
              <Form.Item
                label="Company Logo"
                valuePropName="file"
                tooltip="Only .png or .jpeg supported, size limit: 2MB. This will take up to an hour to show changes in the site logo."
              >
                <Upload
                  name="logo"
                  listType="picture-card"
                  showUploadList={false}
                  // action="http://192.168.10.23:3500/admin/company-profile-logo"
                  maxCount={1}
                  accept="image/png, image/jpeg"
                  withCredentials={true}
                  beforeUpload={(file) => {
                    const isJpgOrPng =
                      file.type === "image/jpeg" || file.type === "image/png";
                    if (!isJpgOrPng) {
                      message.error("You can only upload JPG/PNG file!");
                    }
                    const isLt2M = file.size / 1024 / 1024 < 2;
                    if (!isLt2M) {
                      message.error("Image must smaller than 2MB!");
                    }
                    return isJpgOrPng && isLt2M;
                  }}
                  customRequest={async ({
                    file,
                    filename,
                    data,
                    onProgress,
                    onError,
                    onSuccess,
                  }) => {
                    const formData = new FormData();
                    formData.append(filename, file);
                    try {
                      const { db_full_path } = (
                        await axiosPrivate.post(
                          "/admin/company-profile-logo",
                          formData,
                          {
                            headers: { "Content-Type": "multipart/form-data" },
                            onUploadProgress: ({ total, loaded }) => {
                              onProgress(
                                {
                                  percent: Math.round(
                                    (loaded / total) * 100
                                  ).toFixed(2),
                                },
                                file
                              );
                            },
                          }
                        )
                      ).data;
                      onSuccess({ full_path: db_full_path });
                    } catch (err) {
                      onError(err);
                    }
                    return {
                      abort() {
                        console.log("upload progress is aborted.");
                      },
                    };
                  }}
                  onChange={(fileInfo) => {
                    if (fileInfo.file.status === "uploading") {
                      setUploading(true);
                    } else if (fileInfo.file.status === "done") {
                      setUploading(false);
                      setLogoPath(fileInfo.file.response?.full_path);
                    }
                  }}
                >
                  <div>
                    {logoPath ? (
                      <Image
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                          borderRadius: 10,
                        }}
                        preview={false}
                        src={`${baseUrl}${logoPath}`}
                      />
                    ) : (
                      <>
                        {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div>Upload</div>
                      </>
                    )}
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item
                name="company_name"
                style={{ marginBottom: 10 }}
                label="Company Name"
                rules={[{ required: true, max: 100, type: "string" }]}
                required={true}
              >
                <Input showCount maxLength={100} />
              </Form.Item>

              <Form.Item
                name="gst_hst_registration_number"
                style={{ marginBottom: 10 }}
                label="GST/HST #"
                rules={[{ max: 20, type: "string" }]}
              >
                <Input showCount maxLength={20} />
              </Form.Item>
              <Form.Item
                name="industry"
                style={{ marginBottom: 10 }}
                label="Industry"
                required
                rules={[{ required: true, max: 100, type: "string" }]}
              >
                <Select
                  options={[
                    {
                      label: "Automotive Industry",
                      value: "Automotive Industry",
                    },
                    { label: "Agricaulture", value: "Agricaulture" },
                    { label: "Aerospace", value: "Aerospace" },
                    { label: "Construction", value: "Construction" },
                    {
                      label: "Energy Industry",
                      value: "Energy Industry",
                    },
                    { label: "Forestry", value: "Forestry" },
                    { label: "Health Care", value: "Health Care" },
                    { label: "Logistics", value: "Logistics" },
                    { label: "Mining", value: "Mining" },
                    { label: "Manufacturing", value: "Manufacturing" },
                    {
                      label: "Petroleum Industry",
                      value: "Petroleum Industry",
                    },
                    { label: "Retail", value: "Retail" },
                    { label: "Transportation", value: "Transportation" },
                    { label: "Utilities", value: "Utilities" },
                    { label: "Other", value: "Other" },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="address"
                style={{ marginBottom: 10 }}
                label="Address"
                required={true}
                rules={[{ required: true, type: "string", max: "255" }]}
              >
                <Input showCount maxLength={255} />
              </Form.Item>
              <Form.Item
                name="post_code"
                style={{ marginBottom: 10 }}
                label="Post Code"
                rules={[{ type: "string", max: 50 }]}
              >
                <Input showCount maxLength={50} />
              </Form.Item>
              <Form.Item
                name="city"
                style={{ marginBottom: 10 }}
                label="City"
                required={true}
                rules={[{ required: true, type: "string", max: 100 }]}
              >
                <Input showCount maxLength={100} />
              </Form.Item>
              <Form.Item
                name="province"
                style={{ marginBottom: 10 }}
                required
                label="Province"
                rules={[{ required: true, max: 100, type: "string" }]}
              >
                <Select
                  options={[
                    { label: "Alberta", value: "Alberta" },
                    {
                      label: "British Columbia",
                      value: "British Columbia",
                    },
                    { label: "Manitoba", value: "Manitoba" },
                    { label: "New Brunswick", value: "New Brunswick" },
                    {
                      label: "Newfoundland and Labrador",
                      value: "Newfoundland and Labrador",
                    },
                    { label: "Nova Scotia", value: "Nova Scotia" },
                    { label: "Ontario", value: "Ontario" },
                    {
                      label: "Prince Edward Island",
                      value: "Prince Edward Island",
                    },
                    { label: "Quebec", value: "Quebec" },
                    { label: "Saskatchewan", value: "Saskatchewan" },
                    {
                      label: "Northwest Territories",
                      value: "Northwest Territories",
                    },
                    { label: "Nunavut", value: "Nunavut" },
                    { label: "Yukon", value: "Yukon" },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="country"
                style={{ marginBottom: 10 }}
                required
                label="Country"
                rules={[{ require: true, type: "string", max: 100 }]}
              >
                <Select
                  options={[{ value: "Canada", label: "Canada" }]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="email"
                style={{ marginBottom: 10 }}
                label="Email"
                required
                rules={[{ required: true, type: "string", max: 100 }]}
              >
                <Input maxLength={100} showCount />
              </Form.Item>
              <Form.Item
                name="phone"
                style={{ marginBottom: 10 }}
                label="Phone"
                required
                rules={[{ required: true, max: 100, type: "string" }]}
              >
                <Input maxLength={100} showCount />
              </Form.Item>
              <Form.Item style={{ marginTop: 40 }}>
                <Button htmlType="submit" type="primary" loading={submitting}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </Content>
  );
};

export default Profile;
