import React from "react";
import { Space, Spin, Typography } from "antd";

const Loader = ({ loading = false, tips = "" }) => {
  const { Title } = Typography;

  return (
    loading && (
      <div className="loader-over-lay">
        <Space size="middle" direction="vertical" align="center">
          <Spin spinning={loading} size="large" />
          {tips.length > 0 && <Title level={3}>{tips}</Title>}
        </Space>
      </div>
    )
  );
};

export default Loader;
