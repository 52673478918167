import React from "react";
import { Link } from "react-router-dom";
import { Layout, Typography } from "antd";
const { Content } = Layout;
const { Text, Title } = Typography;

const PageSiteClosed = ({ site_closed_note }) => {
  return (
    <Layout className="layout-404">
      <Content className="middle-404">
        <Content className="title-404">
          <Title
            style={{ color: "#cf1322", fontWeight: "bolder", fontSize: 80 }}
          >
            Site Temporarily Closed
          </Title>
          <Text style={{ color: "#bfbfbf", fontWeight: 450, fontSize: 18 }}>
            {site_closed_note ?? "We are sorry for your inconvenience."}
          </Text>
        </Content>
      </Content>
    </Layout>
  );
};

export default PageSiteClosed;
