import React, { useEffect, useRef, useState } from "react";
import { Layout, Card, Table, Tag } from "antd";
import moment from "moment";
import useAxiosPrivate from "../context/hooks/useAxiosPrivate";
import numberFormatter from "number-formatter";
import SiteHeader from "../Components/SiteHeader";
import SiteFooter from "../Components/SiteFooter";
import LiquidFillGauge from "react-liquid-gauge";
import { Pie, Column, Line, Bar } from "@ant-design/plots";
import millify from "millify";

const { Content } = Layout;

const Overview = () => {
  const axiosPrivate = useAxiosPrivate();
  const initCheck = useRef(true);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [latestRackLoads, setLatestRackLoads] = useState([]);
  const [latestDeliveries, setLatestDeliveries] = useState([]);
  const [loadTrends, setLoadTrends] = useState([]);
  const [customerActivityCounts, setCustomerActivityCounts] = useState([]);
  const [loadPieChartData, setLoadPieChartData] = useState([]);
  const [deliveryPieChartData, setDeliveryPieChartData] = useState([]);
  const [errorOccurence, setErrorOccurence] = useState([]);
  const [customerProductRank, setCustomerProductRank] = useState([]);
  const [appSetting, setAppsetting] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [tmrMetreStatusUpdater, setTmrMetreStatusUpdater] = useState(null);

  const reloadOverviewData = async () => {
    try {
      setIsLoadingStatus(true);
      const {
        connectors,
        loadTrends,
        customerActivityCounts,
        loadPieChartData,
        deliveryPieChartData,
        latestRackLoads,
        latestDeliveries,
        errOccurence,
        top10CustomerProductRank,
        appSetting,
      } = (await axiosPrivate.get("/regular/overview/data")).data;
      setLatestDeliveries(latestDeliveries.latest_deliveries);
      setLatestRackLoads(latestRackLoads.latest_loads);
      setLoadTrends(loadTrends);
      setCustomerActivityCounts(customerActivityCounts.activity_counts);
      setInventory(connectors);
      setErrorOccurence(errOccurence.nodes);
      setCustomerProductRank(top10CustomerProductRank.results);
      setLoadPieChartData(
        loadPieChartData.summary.map((element) => ({
          name: element.product_code,
          value:
            appSetting.gauge_gross_net_choice === "net"
              ? element.net
              : element.gross,
          color_code: element.color_code,
        }))
      );
      setDeliveryPieChartData(
        deliveryPieChartData.summary.map((element) => ({
          name: element.product_code,
          value:
            appSetting.gauge_gross_net_choice === "net"
              ? element.net
              : element.gross,
          color_code: element.color_code,
        }))
      );

      setAppsetting(appSetting);
    } catch (err) {
      //console.error(err);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  useEffect(() => {
    if (initCheck.current) {
      initCheck.current = false;
      (async () => {
        await reloadOverviewData();
      })();
    }

    return () => {
      clearInterval(tmrMetreStatusUpdater);
    };
  }, []);

  return (
    <div className="over-view-content">
      {inventory
        ?.filter((location) => location?.aggregated)
        .map((location) => (
          <Card
            key={location?.connector_id}
            title={`Inventory Overview - ${location?.description}`}
            extra={null}
            style={{ margin: "20px 20px 20px" }}
            bodyStyle={{ padding: 0 }}
          >
            <div className="metre-overview">
              {location?.aggregated?.map((product) => {
                const percentage =
                  ((appSetting.gauge_gross_net_choice === "net"
                    ? product.tank_volume
                    : product.tank_gross_volume) /
                    ((appSetting.gauge_gross_net_choice === "net"
                      ? product.tank_volume
                      : product.tank_gross_volume) +
                      product.tank_adjusted_ullage)) *
                  100;
                return (
                  <div
                    key={product.product_code}
                    className="overview-inventory-tile"
                  >
                    <div className="overview-inventory-tile-title">
                      {product.product_code}
                    </div>
                    <div className="overview-inventory-tile-body">
                      <div className="overview-inventory-tile-body-left">
                        <div className="overview-inventory-tile-volume">
                          {numberFormatter(
                            "#,###,###,##0.00",
                            appSetting.gauge_gross_net_choice === "net"
                              ? product.tank_volume
                              : product.tank_gross_volume
                          )}
                          &nbsp;{appSetting.volume_unit}
                        </div>
                        <div style={{ fontSize: "1.05rem", fontWeight: 500 }}>
                          Ullage:&nbsp;
                          {numberFormatter(
                            "#,###,###,##0.00",
                            product.tank_adjusted_ullage
                          )}
                          &nbsp;{appSetting.volume_unit}
                        </div>
                      </div>
                      <div className="overview-inventory-tile-body-right">
                        <LiquidFillGauge
                          style={{ margin: "0 auto" }}
                          width={70}
                          height={70}
                          value={percentage}
                          percent="%"
                          textSize={1.2}
                          waveAnimation
                          waveFrequency={2}
                          waveAmplitude={1}
                          circleStyle={{
                            fill: product.color_code,
                          }}
                          waveStyle={{
                            fill: product.color_code,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
        ))}

      <Content className="overview-chart">
        <Card
          className="overview-chart-card"
          title={`Latest Transactions Excl. Offload`}
        >
          <Table
            size="small"
            bordered
            loading={isLoadingStatus}
            columns={[
              {
                title: "TimeStamp",
                dataIndex: "load_stop",
                dataIndex: "load_stop",
                align: "center",
                width: 100,
                render: (text, record, index) =>
                  moment(text).format("MM-DD HH:mm"),
              },
              {
                title: "Location",
                dataIndex: "location",
                key: "location",
                align: "center",
                responsive: ["xl"],
              },
              {
                title: "Product",
                dataIndex: "product_code",
                key: "product_code",
                align: "center",
                width: 120,
              },
              {
                title: `Volume`,
                dataIndex:
                  appSetting.gauge_gross_net_choice === "net" ? "net" : "gross",
                key:
                  appSetting.gauge_gross_net_choice === "net" ? "net" : "gross",
                align: "right",
                width: 110,
                render: (text, record, index) =>
                  `${numberFormatter("#,###,###,##0.00", text)} ${
                    appSetting.volume_unit
                  }`,
              },
            ]}
            rowKey={(record) => record.load_id}
            dataSource={latestRackLoads}
            pagination={{ position: ["none", "none"], defaultPageSize: 100 }}
          />
        </Card>
        <Card className="overview-chart-card" title={`Latest Deliveries`}>
          <Table
            size="small"
            bordered
            loading={isLoadingStatus}
            columns={[
              {
                title: "TimeStamp",
                dataIndex: "delivery_end",
                dataIndex: "delivery_end",
                align: "center",
                width: 100,
                render: (text, record, index) =>
                  moment(text).format("MM-DD HH:mm"),
              },
              {
                title: "Location",
                dataIndex: "description",
                key: "description",
                align: "center",
                responsive: ["xl"],
              },
              {
                title: "Product",
                dataIndex: "product_code",
                key: "product_code",
                align: "center",
                width: 120,
              },
              {
                title: `Volume`,
                dataIndex:
                  appSetting.gauge_gross_net_choice === "net" ? "net" : "gross",
                key:
                  appSetting.gauge_gross_net_choice === "net" ? "net" : "gross",
                align: "right",
                width: 120,
                render: (text, record, index) =>
                  `${numberFormatter("#,###,###,##0.00", text)} ${
                    appSetting.volume_unit
                  }`,
              },
            ]}
            rowKey={(record) => record.delivery_id}
            dataSource={latestDeliveries}
            pagination={{ position: ["none", "none"], defaultPageSize: 100 }}
          />
        </Card>
        <Card
          className="overview-chart-card"
          title={`Top 5 Most Active Customer of Year ${moment().year()}`}
        >
          <Table
            size="small"
            bordered
            loading={isLoadingStatus}
            columns={[
              {
                title: "Rank",
                align: "center",
                width: 40,
                render: (text, record, index) => (
                  <Tag color="volcano">{index + 1}</Tag>
                ),
              },
              {
                title: "Customer",
                dataIndex: "customer_name",
                key: "customer_name",
                align: "left",
              },
              {
                title: "Loads",
                dataIndex: "numOfLoads",
                key: "numOfLoads",
                align: "center",
                width: 55,
              },
              {
                title: "Last Load",
                dataIndex: "last_activity_timestamp",
                key: "last_activity_timestamp",
                align: "center",
                width: 100,
                render: (text, record, index) => {
                  return moment(text).format("MM-DD");
                },
                responsive: ["xl"],
              },
            ]}
            rowKey={(record) => record.account_number}
            dataSource={customerActivityCounts}
            pagination={{ position: ["none", "none"], defaultPageSize: 100 }}
          />
        </Card>
        <Card
          className="overview-chart-card"
          title={`Top 5 Most Promising Customer of Year ${moment().year()}`}
        >
          <Bar
            height={250}
            data={customerProductRank}
            isGroup={true}
            xField={
              appSetting.gauge_gross_net_choice === "net" ? "net" : "gross"
            }
            yField="customer_name"
            seriesField="product_alias"
            marginRatio={0}
            xAxis={{
              label: {
                formatter: (data) =>
                  `${millify(Number(data))} ${appSetting.volume_unit}`,
                autoEllipsis: true,
              },
            }}
            tooltip={{
              formatter: (data) => {
                return {
                  name: data.product_alias,
                  value: `${millify(
                    Number(
                      appSetting.gauge_gross_net_choice === "net"
                        ? data.net
                        : data.gross
                    )
                  )} ${appSetting.volume_unit}`,
                };
              },
            }}
          />
        </Card>
        <Card
          className="overview-chart-card"
          title={`3-Month Daily Transaction Trends`}
        >
          {loadTrends.trends?.length ? (
            <Column
              smooth
              height={250}
              data={loadTrends?.trends}
              seriesField="product"
              xField="date"
              yField={
                appSetting.gauge_gross_net_choice === "net" ? "net" : "gross"
              }
              color={({ product }) => {
                const color_code = loadTrends.colors.find(
                  (color) => color.product === product
                )?.color_code;
                return color_code;
              }}
              yAxis={{
                label: {
                  formatter: (data) =>
                    `${millify(data)} ${appSetting.volume_unit}`,
                },
              }}
              xAxis={{
                label: {
                  formatter: (data) => moment(data).format("MM-DD"),
                  autoRotate: true,
                },
              }}
              tooltip={{
                formatter: (data) => {
                  return {
                    name: data.product,
                    value: `${millify(
                      appSetting.gauge_gross_net_choice === "net"
                        ? data.net
                        : data.gross
                    )} ${appSetting.volume_unit}`,
                  };
                },
              }}
            />
          ) : (
            <center>Not enough data to generate report.</center>
          )}
        </Card>
        <Card
          className="overview-chart-card"
          title={`${moment()
            .startOf("month")
            .format("MMMM")} Transaction Summary`}
        >
          {loadPieChartData.length ? (
            <Pie
              height={250}
              data={loadPieChartData}
              angleField="value"
              colorField="name"
              color={({ name }) => {
                return loadPieChartData.find((data) => data.name === name)
                  ?.color_code;
              }}
              radius={0.8}
              label={{
                position: "spider",
                content: (d) => {
                  return `${millify(d.value)} ${appSetting.volume_unit}`;
                },
              }}
              tooltip={{
                formatter: (data) => {
                  return {
                    name: data.name,
                    value: `${millify(data.value)} ${appSetting.volume_unit}`,
                  };
                },
              }}
              legend={{
                color: {
                  title: false,
                  position: "right",
                  rowPadding: 5,
                },
              }}
            />
          ) : (
            <center>Not enough data to generate report.</center>
          )}
        </Card>
        <Card
          className="overview-chart-card"
          title={`${moment().startOf("month").format("MMMM")} Delivery Summary`}
        >
          {deliveryPieChartData.length ? (
            <Pie
              height={250}
              data={deliveryPieChartData}
              angleField="value"
              colorField="name"
              color={({ name }) => {
                return deliveryPieChartData.find((data) => data.name === name)
                  ?.color_code;
              }}
              radius={0.8}
              label={{
                position: "spider",
                content: (d) => {
                  return `${millify(d.value)} ${appSetting.volume_unit}`;
                },
              }}
              tooltip={{
                formatter: (data) => {
                  return {
                    name: data.name,
                    value: `${millify(data.value)} ${appSetting.volume_unit}`,
                  };
                },
              }}
              legend={{
                color: {
                  title: false,
                  position: "right",
                  rowPadding: 5,
                },
              }}
            />
          ) : (
            <center>Not enough data to generate report.</center>
          )}
        </Card>
        <Card
          className="overview-chart-card"
          title={`3-Month Metre Error Occurence`}
        >
          {errorOccurence.length ? (
            <Line
              height={250}
              data={errorOccurence}
              padding={"auto"}
              xField="date"
              yField="count"
              smooth={true}
              xAxis={{
                label: {
                  formatter: (data) => moment(data).format("MM-DD"),
                  autoRotate: true,
                },
              }}
            />
          ) : (
            <center>Not enough data to generate report.</center>
          )}
        </Card>
      </Content>
    </div>
  );
};

export default Overview;
