import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import zeroFill from "zero-fill";
import { LuComponent, LuGauge } from "react-icons/lu";
import {
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
  SaveFilled,
  LeftCircleFilled,
} from "@ant-design/icons";
import {
  Layout,
  Form,
  Table,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Modal,
  Space,
  Breadcrumb,
  Typography,
  Button,
  Tooltip,
  notification,
} from "antd";

import useAxiosPrivate from "../../context/hooks/useAxiosPrivate";
import LoaderRelative from "../../Components/LoaderRelative";

import cloneDeep from "lodash.clonedeep";
import { AxiosError } from "axios";
const { Content } = Layout;
const { Search } = Input;
const { Text } = Typography;
const AdminPresets = () => {
  const [hasInitialised, setHasInitialised] = useState(false);
  const [presets, setPresets] = useState([]);
  const [resPreset, setResPreset] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formAction, setFormAction] = useState("add");
  const [searchKey, setSearchKey] = useState("");
  const [submitSearchKey, setSubmitSearchKey] = useState("");
  const [currentPresetId, setCurrentPresetId] = useState(null);
  const [currentPreset, setCurrentPreset] = useState(null);
  const [currentProducts, setCurrentProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [modal, contextHolderConfirm] = Modal.useModal();
  const [currentMetre, setCurrentMetre] = useState(null);
  const [frmPreset] = Form.useForm();
  const [frmPresetMetre] = Form.useForm();
  const { id: current_metre_id } = useParams();
  const [isLoadingPresetDetail, setIsLoadingPresetDetail] = useState(false);
  const [isPresetMetreModalOpen, setIsPresetMetreModalOpen] = useState(false);
  const [presetMetres, setPresetMetres] = useState([]);
  const [isPresetComponentModalOpen, setIsPresetComponentModalOpen] =
    useState(false);
  const [presetComponents, setPresetComponents] = useState([]);
  const [editingMetreRow, setEditingMetreRow] = useState(null);

  const columns = [
    {
      title: "Index",
      dataIndex: "preset_index",
      align: "center",
      defaultSortOrder: "ascend",
      width: 120,
      sorter: (a, b) => {
        return a.preset_index > b.preset_index;
      },
      render: (text, record, index) => zeroFill(3, text),
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      align: "left",
      defaultSortOrder: "ascend",
      width: 180,
      sorter: (a, b) => {
        return a.display_name > b.display_name;
      },
      // render: (text, record, index) => {
      //   return <Text>{text}</Text>;
      // },
    },
    {
      title: "Metre Name",
      dataIndex: "metre_name",
      align: "left",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        return a.metre_name > b.metre_name;
      },
      // render: (text, record, index) => {
      //   return (
      //     <Text
      //       style={{ textDecoration: record.disabled ? "line-through" : "" }}
      //     >
      //       {text}
      //     </Text>
      //   );
      // },
    },
    {
      title: "Override",
      dataIndex: "override_metre_name",
      // sorter: true,
      align: "center",
      width: 150,
      // defaultSortOrder: "ascend",
      // sorter: (a, b) => {
      //   return a.metre_name > b.metre_name;
      // },
      render: (text, record, index) => {
        return record.override_metre_name ? "Yes" : "No";
      },
    },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 150,
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Link
              onClick={() => {
                loadPresetMetresCallback(record.preset_id);
              }}
            >
              <Tooltip title="Metres">
                <LuGauge style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Link
              onClick={() => {
                loadPresetMComponentsCallback(record.preset_id);
              }}
            >
              <Tooltip title="Components">
                <LuComponent style={{ fontSize: 15, color: "#4096ff" }} />
              </Tooltip>
            </Link>
            <Link
              onClick={() => {
                handlerShowDetail(record.preset_id);
              }}
            >
              <EditFilled style={{ fontSize: 15, color: "#4096ff" }} />
            </Link>
            <Link
              onClick={() => {
                handlerDelete(record.preset_id);
              }}
            >
              <DeleteFilled style={{ fontSize: 15, color: "#cf1322" }} />
            </Link>
          </Space>
        );
      },
    },
  ];

  const columnsMetre = [
    {
      title: "Index",
      dataIndex: "metre_index",
      align: "center",
      defaultSortOrder: "ascend",
      width: 120,
      readonly: true,
    },
    {
      title: "Name",
      dataIndex: "metre_name",
      align: "left",
      sorter: false,
      onCell: (record, index) => {
        return {
          onClick: (e) => {
            if (e.target.tagName !== "TD") return;
            setEditingMetreRow({
              rowIndex: index,
              record: cloneDeep(record),
            });
            frmPresetMetre.setFieldsValue({
              metre_name: record.metre_name,
            });
          },
        };
      },
      render: (text, record, index) =>
        editingMetreRow?.record?.metre_index === record.metre_index ? (
          <Form.Item
            style={{ margin: 0 }}
            name={"metre_name"}
            rules={[
              {
                type: "string",
                min: 3,
                max: 10,
                required: true,
                message: "",
              },
            ]}
          >
            <Input maxLength={10} minLength={3} showCount allowClear />
          </Form.Item>
        ) : (
          text
        ),
    },
    {
      title: "Action",
      valueType: "option",
      width: 80,
      align: "center",
      render: (text, record, index) => {
        return (
          <Space size="small">
            <Link hidden={editingMetreRow?.rowIndex === index}>
              <EditFilled
                style={{ fontSize: 15, color: "#4096ff" }}
                onClick={() => {
                  setEditingMetreRow({
                    rowIndex: index,
                    record: cloneDeep(record),
                  });
                  frmPresetMetre.setFieldsValue({
                    metre_name: record.metre_name,
                  });
                }}
              />
            </Link>
            <Link hidden={editingMetreRow?.rowIndex !== index}>
              <LeftCircleFilled
                style={{ fontSize: 15, color: "#4096ff" }}
                onClick={() => {
                  setEditingMetreRow(null);
                }}
              />
            </Link>
            <Link hidden={editingMetreRow?.rowIndex !== index}>
              <SaveFilled
                style={{ fontSize: 15, color: "#4096ff" }}
                onClick={() => {
                  frmPresetMetre.submit();
                }}
              />
            </Link>
          </Space>
        );
      },
    },
  ];

  const columnsComponents = [
    {
      title: "Idx",
      dataIndex: "component_index",
      align: "center",
      defaultSortOrder: "ascend",
      width: 80,
      // sorter: (a, b) => {
      //   return a.preset_index > b.preset_index;
      // },
      // render: (text, record, index) => zeroFill(3, text),
    },
    {
      title: "Metre",
      dataIndex: "metre_index",
      align: "center",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => {
      //   return a.preset_index > b.preset_index;
      // },
      render: (text, record, index) =>
        `[${record.metre_index}] ${record.metre_name}`,
    },
    {
      title: "Product",
      dataIndex: "product_code",
      align: "left",
      sorter: false,
      // sorter: (a, b) => {
      //   return a.preset_index > b.preset_index;
      // },
      // render: (text, record, index) => zeroFill(3, text),
    },
    {
      title: "API Gravity",
      dataIndex: "api_gravity",
      align: "center",
      sorter: false,
      width: 120,
      // sorter: (a, b) => {
      //   return a.preset_index > b.preset_index;
      // },
      // render: (text, record, index) => zeroFill(3, text),
    },
    {
      title: "Default Density",
      dataIndex: "default_density",
      align: "center",
      sorter: false,
      width: 120,
      // sorter: (a, b) => {
      //   return a.preset_index > b.preset_index;
      // },
      // render: (text, record, index) => zeroFill(3, text),
    },
    {
      title: "Relative Density",
      dataIndex: "relative_density",
      align: "center",
      sorter: false,
      width: 120,
      // sorter: (a, b) => {
      //   return a.preset_index > b.preset_index;
      // },
      // render: (text, record, index) => zeroFill(3, text),
    },
  ];

  useEffect(() => {
    const timeOutId = setTimeout(() => setSubmitSearchKey(searchKey), 500);
    return () => clearTimeout(timeOutId);
  }, [searchKey]);

  const initCheck = useRef(true);
  useEffect(() => {
    if (initCheck.current || hasInitialised) {
      initCheck.current = false;
      (async () => {
        try {
          setIsLoading(true);
          await loadPresets(current_metre_id);
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setIsLoading(false);
          setHasInitialised(true);
        }
      })();
    }
  }, [submitSearchKey]);

  const loadPresets = async (metre_id) => {
    const res_presets = await axiosPrivate.get(
      `/admin/meter/${metre_id}/presets?q=${encodeURIComponent(searchKey)}`
    );
    const { presets, metre } = res_presets.data;
    setCurrentMetre(metre);
    setPresets(presets);
  };

  const handlerSearchChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handleOk = async (close) => {
    frmPreset.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    frmPreset.resetFields();
  };

  const handlerShowDetail = async (preset_id) => {
    try {
      setIsLoadingPresetDetail(true);
      const res_preset = await axiosPrivate.get(
        `/admin/meters/${current_metre_id}/presets/${preset_id}`
      );
      setResPreset(res_preset.data);
      setCurrentPresetId(preset_id);
      setFormAction("edit");
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoadingPresetDetail(false);
    }
  };

  const handlerDelete = async (preset_id) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete this preset?",
      onOk: async () => {
        try {
          setSubmitting(true);
          const res_delete_preset = await axiosPrivate.delete(
            `/admin/meter/${current_metre_id}/preset/${preset_id}`
          );
          notification.success({
            message: "Successfully deleted the preset.",
            placement: "bottom",
            duration: 4,
          });
          setPresets((state) => {
            const new_state = state.filter((m) => m.preset_id !== preset_id);
            return [...new_state];
          });
          setIsModalOpen(false);
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });
  };

  const handleFormSubmit = async (data) => {
    try {
      setSubmitting(true);
      if (formAction === "add") {
        const res_add = await axiosPrivate.post(
          `/admin/meters/${current_metre_id}/presets`,
          data
        );
        notification.success({
          message: "Successfully created new preset.",
          placement: "bottom",
          duration: 4,
        });
        setPresets((state) => {
          return [...state, { ...res_add.data }];
        });
      } else if (formAction === "edit") {
        const res_update_preset = await axiosPrivate.patch(
          `/admin/meters/${current_metre_id}/presets/${currentPresetId}`,
          { ...data }
        );
        notification.success({
          message: `Successfully updated preset.`,
          placement: "bottom",
          duration: 4,
        });
        setPresets((state) => {
          let found_preset_index = state.findIndex(
            (metre) => metre.preset_id === currentPresetId
          );
          state[found_preset_index] = { ...res_update_preset.data };
          return [...state];
        });
      }
      frmPreset.resetFields();
      setIsModalOpen(false);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const getProductOptions = () => {
    const options = [];
    options.push({
      label: "Base Components",
      options: currentProducts
        ? currentProducts
            .filter((product) => product.product_type === "component")
            .map((product) => ({
              label: product.short_desc,
              value: product.product_id,
            }))
        : [],
    });
    options.push({
      label: "Base Additives",
      options: currentProducts
        ? currentProducts
            .filter((product) => product.product_type === "additive")
            .map((product) => ({
              label: product.short_desc,
              value: product.product_id,
            }))
        : [],
    });
    options.push({
      label: "Saleable Products",
      options: currentProducts
        ? currentProducts
            .filter((product) => product.product_type === "saleable_product")
            .map((product) => ({
              label: product.short_desc,
              value: product.product_id,
            }))
        : [],
    });
    return options;
  };

  const uploadPresetDefinitionsCallback = async () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to upload the preset definitions for metre ${currentMetre.display_name}?`,
      onOk: async () => {
        try {
          setIsLoading(true);
          const resUploadPresetDefinitions = await axiosPrivate.get(
            `/admin/meters/${current_metre_id}/upload-preset-definitions`
          );
          notification.success({
            message: "Successfully uploaded the preset definition.",
            placement: "bottom",
            duration: 4,
          });
        } catch (err) {
          notification.error({
            message:
              err instanceof AxiosError
                ? err.response.data
                : err?.message ?? err?.toString(),
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setIsLoading(false);
        }
      },
    });
  };

  const loadPresetMetresCallback = async (preset_id) => {
    try {
      setIsLoadingPresetDetail(true);
      const resPresetMetres = await axiosPrivate.get(
        `/admin/meters/${current_metre_id}/presets/${preset_id}/metres`
      );
      const { presetMetres } = resPresetMetres.data;
      setPresetMetres(presetMetres);
      setIsPresetMetreModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoadingPresetDetail(false);
    }
  };

  const loadPresetMComponentsCallback = async (preset_id) => {
    try {
      setIsLoadingPresetDetail(true);
      const resPresetMetres = await axiosPrivate.get(
        `/admin/meters/${current_metre_id}/presets/${preset_id}/components`
      );
      const { presetComponents } = resPresetMetres.data;
      setPresetComponents(presetComponents);
      setIsPresetComponentModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoadingPresetDetail(false);
    }
  };

  const handleFromPresetMetreCallback = async (data) => {
    try {
      setSubmitting(true);
      const resUpdate = await axiosPrivate.patch(
        `/admin/meters/${editingMetreRow?.record?.metre_id}/presets/${editingMetreRow?.record?.preset_id}/metres/${editingMetreRow?.record?.metre_index}`,
        { ...data, preset_index: editingMetreRow?.record?.preset_index }
      );

      let updatedPresetMetres = [...presetMetres];
      updatedPresetMetres[editingMetreRow.rowIndex] = resUpdate.data;
      setPresetMetres(updatedPresetMetres);
    } catch (err) {
    } finally {
      setEditingMetreRow(null);
      setSubmitting(false);
    }
  };

  return (
    <Content
      style={{
        display: "flex",
        flexFlow: "column",
        overflow: "hidden",
        gap: 5,
      }}
    >
      {contextHolderConfirm}
      <Modal
        title={formAction === "add" ? "Add New Preset" : "Edit Preset"}
        open={isModalOpen}
        onOk={handleOk}
        okText="Save"
        okButtonProps={{
          disabled: submitting,
          loading: submitting,
          htmlType: "submit",
        }}
        onCancel={handleCancel}
        afterClose={() => {
          frmPreset.resetFields();
        }}
        afterOpenChange={(open) => {
          if (open && formAction === "edit") {
            const { preset, products, preset_products } = resPreset;
            const {
              load_mode,
              preset_index,
              display_name,
              metre_name,
              override_metre_name,
              disabled,
              number_of_components,
              number_of_additives,
            } = preset;
            let components = preset_products
              .filter((product) => product.product_type === "component")
              .map((preset_product) => preset_product.product_id);
            let additives = preset_products
              .filter((product) => product.product_type === "additive")
              .map((preset_product) => preset_product.product_id);
            let authorised_products = preset_products
              .filter((product) => product.product_type === "authorised")
              .map((preset_product) => preset_product.product_id);

            if (components.length < number_of_components) {
              components.push(
                ...new Array(number_of_components - components.length).fill(
                  "",
                  0
                )
              );
            }
            if (additives.length < number_of_additives) {
              additives.push(
                ...new Array(number_of_additives - additives.length).fill("", 0)
              );
            }
            console.log(components);
            frmPreset.setFieldsValue({
              load_mode,
              preset_index,
              display_name,
              metre_name,
              override_metre_name: override_metre_name ? true : false,
              disabled: disabled ? true : false,
              components,
              additives,
              authorised_products,
            });
            setCurrentPreset(preset);
            setCurrentProducts(products);
          }
        }}
        cancelButtonProps={{ disabled: submitting }}
        destroyOnClose
      >
        <Content>
          <Form
            form={frmPreset}
            requiredMark
            layout="horizontal"
            labelCol={{ span: 8, offset: 0 }}
            labelAlign="left"
            labelWrap
            onFinish={handleFormSubmit}
            disabled={submitting}
          >
            <Form.Item
              required
              label="Preset Index"
              name="preset_index"
              style={{ marginBottom: 10 }}
              rules={[
                {
                  required: true,
                  min: 0,
                  max: 99,
                  message: "",
                  type: "integer",
                },
              ]}
            >
              <InputNumber max={99} min={0} />
            </Form.Item>
            <Form.Item
              required
              label="Display Name"
              name="display_name"
              style={{ marginBottom: 10 }}
              rules={[
                {
                  required: true,
                  min: 3,
                  max: 50,
                  message: "",
                  type: "string",
                },
              ]}
            >
              <Input minLength={3} maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              label="Metre Name"
              name="metre_name"
              style={{ marginBottom: 10 }}
              rules={[{ max: 50, message: "", type: "string" }]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
            <Form.Item
              label="Load Mode"
              name="load_mode"
              style={{ marginBottom: 10 }}
              rules={[{ message: "", type: "string" }]}
            >
              <Select
                options={[
                  { label: "Load", value: "load" },
                  { label: "Offload", value: "offload" },
                  { label: "Both", value: "both" },
                ]}
              />
            </Form.Item>
            <Form.Item label="COMP Assignment" style={{ marginBottom: 10 }}>
              <Form.List name="components">
                {(fields) => {
                  return fields.map((field) => (
                    <Form.Item {...field} style={{ marginBottom: 10 }}>
                      <Select
                        options={[
                          { label: "undefined", value: "" },
                          ...currentProducts
                            .filter(
                              (product) => product.product_type === "component"
                            )
                            .map((product) => ({
                              label: product.short_desc,
                              value: product.product_id,
                            })),
                        ]}
                      />
                    </Form.Item>
                  ));
                }}
              </Form.List>
            </Form.Item>
            <Form.Item label="ADDT Assignment" style={{ marginBottom: 10 }}>
              <Form.List name="additives">
                {(fields) => {
                  return fields.map((field) => (
                    <Form.Item {...field} style={{ marginBottom: 10 }}>
                      <Select
                        options={[
                          { label: "undefined", value: "" },
                          ...currentProducts
                            .filter(
                              (product) => product.product_type === "additive"
                            )
                            .map((product) => ({
                              label: product.short_desc,
                              value: product.product_id,
                            })),
                        ]}
                      />
                    </Form.Item>
                  ));
                }}
              </Form.List>
            </Form.Item>
            <Form.Item
              label="Authorized Products"
              name="authorised_products"
              style={{ marginBottom: 10 }}
            >
              <Select
                mode="multiple"
                maxTagCount={3}
                showSearch={false}
                options={getProductOptions()}
              />
            </Form.Item>
            <Form.Item
              name="override_metre_name"
              valuePropName="checked"
              style={{ marginBottom: 10 }}
            >
              <Checkbox>Override Metre Name</Checkbox>
            </Form.Item>
            <Form.Item
              name="disabled"
              valuePropName="checked"
              style={{ marginBottom: 10 }}
            >
              <Checkbox>Disabled</Checkbox>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
      <Modal
        width={500}
        style={{ minWidth: 500 }}
        title={"Preset Metres"}
        open={isPresetMetreModalOpen}
        onOk={() => {
          setIsPresetMetreModalOpen(false);
        }}
        okText="Close"
        cancelButtonProps={{ hidden: true }}
        onCancel={() => {
          setIsPresetMetreModalOpen(false);
        }}
        destroyOnClose
        afterClose={() => {
          setEditingMetreRow(null);
        }}
      >
        <Form
          form={frmPresetMetre}
          onFinish={handleFromPresetMetreCallback}
          disabled={submitting}
          onKeyDown={(e) => {
            e.code === "Enter" && frmPresetMetre.submit();
          }}
        >
          <Table
            size="small"
            sticky
            pagination={{ position: ["none", "none"], defaultPageSize: 100 }}
            bordered
            columns={columnsMetre}
            rowKey={(presetMetre) => presetMetre.metre_index}
            dataSource={presetMetres}
            showSorterTooltip={false}
          />
        </Form>
      </Modal>
      <Modal
        width={900}
        style={{ minWidth: 900 }}
        title={"Preset Components"}
        open={isPresetComponentModalOpen}
        onOk={() => {
          setIsPresetComponentModalOpen(false);
        }}
        okText="Close"
        cancelButtonProps={{ hidden: true }}
        onCancel={() => {
          setIsPresetComponentModalOpen(false);
        }}
        destroyOnClose
      >
        <Table
          size="small"
          sticky
          pagination={{ position: ["none", "none"], defaultPageSize: 100 }}
          bordered
          columns={columnsComponents}
          rowKey={(presetComponent) => presetComponent.component_index}
          dataSource={presetComponents}
          showSorterTooltip={false}
        />
      </Modal>
      <div className="admin-metre-toolbar">
        <div>
          <Breadcrumb
            items={[
              {
                title: <Link to="/admin">Admin Dashboard</Link>,
              },
              {
                title: <Link to="/admin/metres">Metres</Link>,
              },
              {
                title: (
                  <Text style={{ fontWeight: 500 }}>
                    {currentMetre?.display_name}
                  </Text>
                ),
              },
              {
                title: <Text style={{ fontWeight: 500 }}>Presets</Text>,
              },
            ]}
          />
        </div>
        <div>
          <Space>
            <Search
              placeholder="Key in to search"
              size="small"
              style={{ width: 300 }}
              onChange={handlerSearchChange}
            />
            <Button size="small" onClick={uploadPresetDefinitionsCallback}>
              Upload Preset Definitions
            </Button>
          </Space>
        </div>
      </div>
      <div className="admin-table-wrap">
        <LoaderRelative loading={isLoadingPresetDetail} />
        <Table
          sticky
          pagination={{ position: ["none", "none"], defaultPageSize: 100 }}
          bordered
          columns={columns}
          rowKey={(preset) => preset.preset_id}
          dataSource={presets}
          loading={isLoading}
          showSorterTooltip={false}
          rowClassName={(record) => record.disabled && "disabled-record"}
        />
      </div>
    </Content>
  );
};

export default AdminPresets;
