import React from "react";
import { Layout, Menu } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import SiteHeader from "../../Components/SiteHeader";
import SiteFooter from "../../Components/SiteFooter";
import useAuth from "../../context/hooks/useAuth";
const { Content } = Layout;

const AdminLayout = () => {
  const { auth } = useAuth();

  let parsedCookies = {};
  if (auth.accessToken) {
    parsedCookies = jwt_decode(auth.accessToken);
  }
  const { roles } = parsedCookies.UserInfo;
  const current_location = useLocation();
  const current_pathname = current_location.pathname;
  const items = [
    {
      label: <Link to={"/admin"}>Profile</Link>,
      key: "Business Profile",
      pathname: [
        "/admin",
        "/admin/settings",
        "/admin/smtp-setting",
        "/admin/office-location",
      ],
    },
    {
      label: <Link to={"/admin/users"}>Users</Link>,
      key: "user-management",
      pathname: ["/admin/users"],
    },
    {
      label: (
        <Link to={"/admin/connectors"} hidden={!["9000"].includes(roles)}>
          Connectors
        </Link>
      ),
      key: "connector-management",
      pathname: ["/admin/connectors"],
    },
    {
      label: (
        <Link to={"/admin/metres"} hidden={!["9000"].includes(roles)}>
          Metres
        </Link>
      ),
      key: "metre-management",
      pathname: ["/admin/metres"],
      reg: /\/admin\/metres\/\d+\/presets|products|logics/g,
    },
    {
      label: (
        <Link to={"/admin/gauges"} hidden={!["9000"].includes(roles)}>
          Gauges
        </Link>
      ),
      key: "gauge-management",
      pathname: ["/admin/gauges"],
      reg: /\/admin\/gauges\/\d+\/tanks/g,
    },
    {
      label: (
        <Link to={"/admin/unprocessed-bols"} hidden={!["9000"].includes(roles)}>
          BOL Logs
        </Link>
      ),
      key: "unprocessed-bols",
      pathname: ["/admin/unprocessed-bols"],
    },
  ];

  const current_active_menu_keys = items
    .filter(
      (item) =>
        item.pathname.includes(current_pathname) ||
        (item.reg && item.reg.test(current_pathname))
    )
    .map((i) => i.key);

  return (
    <Layout className="layout">
      <SiteHeader />
      <Menu
        defaultSelectedKeys={["overview"]}
        selectedKeys={[...current_active_menu_keys]}
        mode="horizontal"
        items={items}
      />
      <Content className="admin-over-view-content">
        <Outlet />
      </Content>
      <SiteFooter />
    </Layout>
  );
};

export default AdminLayout;
