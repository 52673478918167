import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import jwt_decode from "jwt-decode";
import useAuth from "../../context/hooks/useAuth";
import {
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

import useAxiosPrivate from "../../context/hooks/useAxiosPrivate";
import {
  Layout,
  Form as AntForm,
  Table,
  Button,
  Input,
  Checkbox,
  Modal,
  Space,
  Select,
  notification,
} from "antd";
const { Content } = Layout;
const { Search } = Input;
const AdminUsers = () => {
  const { auth } = useAuth();
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formAction, setFormAction] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  const [locations, setLocations] = useState([]);
  const [metreGroups, setMetreGroups] = useState([]);
  const [gaugeGroups, setGaugeGroups] = useState([]);
  const [officeLocations, setOfficeLocations] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [seachUsersStatus, setSearchUsersStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const {
    handleSubmit,
    control,
    reset,
    setValue,

    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
      permitted_locations: [],
      permitted_metres: [],
      permitted_gauges: [],
      display_name: "",
      department: "",
      location: "",
      email: "",
      mobile: "",
      roles: "",
      disabled: false,
      app_enabled: false,
      app_push_notification: false,
      app_device_connection_notification: false,
      app_load_notification: false,
      app_delivery_notification: false,
      app_tank_notification: false,
      app_maintenance_access: "none",
    },
  });
  const [modal, contextHolderConfirm] = Modal.useModal();
  let parsedCookies = {};
  if (auth.accessToken) {
    parsedCookies = jwt_decode(auth.accessToken);
  }
  const { roles } = parsedCookies.UserInfo;

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      align: "left",
      defaultSortOrder: "ascend",
      width: 300,
      sorter: (a, b) => {
        return a.username > b.username;
      },
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      align: "left",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        return a.display_name > b.display_name;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left",
    },
    // {
    //   title: "Roles",
    //   dataIndex: "roles",
    //   align: "center",
    //   width: 200,
    // },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Link
              onClick={() => {
                handlerShowDetail(record.user_id);
              }}
            >
              <EditFilled style={{ fontSize: 15, color: "#4096ff" }} />
            </Link>
            <Link
              onClick={() => {
                handlerDelete(record.user_id);
              }}
            >
              <DeleteFilled style={{ fontSize: 15, color: "#cf1322" }} />
            </Link>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        await loadUsers();
      })();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const initCheck = useRef(true);
  const hasInit = useRef(false);
  useEffect(() => {
    if (initCheck.current || hasInit.current) {
      initCheck.current = false;
      (async () => {
        await loadUsers();
        hasInit.current = true;
      })();
    }
  }, [seachUsersStatus]);

  const handlerSearchUserDisabledChanged = async (status) => {
    setSearchUsersStatus(status);
  };

  const loadUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(
        `/admin/users?q=${encodeURIComponent(
          searchTerm
        )}&d=${encodeURIComponent(seachUsersStatus)}`
      );

      const { users, metreGroups, gaugeGroups, locations, office_locations } =
        data;
      setLocations(locations);
      setMetreGroups(metreGroups);
      setGaugeGroups(gaugeGroups);
      setUsers(users);
      setOfficeLocations(office_locations);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlerSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlerShowForm = () => {
    setFormAction("add");
    setIsModalOpen(true);
  };
  const handleOk = async (close) => {
    formRef.current.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    reset();
  };

  const handlerShowDetail = async (user_id) => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.get(`/admin/user/${user_id}`);
      setValue("username", data.username);
      setValue("password", "");
      setValue("permitted_locations", data.permitted_locations.split(","));
      setValue("permitted_metres", data.permitted_metres.split(","));
      setValue("permitted_gauges", data.permitted_gauges.split(","));
      setValue("roles", data.roles);
      setValue("email", data.email);
      setValue("display_name", data.display_name);
      setValue("location", data.location);
      setValue("department", data.department);
      setValue("mobile", data.mobile);
      setValue("disabled", data.disabled ? true : false);
      setValue("app_enabled", data.app_enabled ? true : false);
      setValue("app_maintenance_access", data.app_maintenance_access);
      setValue(
        "app_push_notification",
        data.app_push_notification ? true : false
      );
      setValue(
        "app_device_connection_notification",
        data.app_device_connection_notification ? true : false
      );
      setValue(
        "app_load_notification",
        data.app_load_notification ? true : false
      );
      setValue(
        "app_delivery_notification",
        data.app_delivery_notification ? true : false
      );
      setValue(
        "app_tank_notification",
        data.app_tank_notification ? true : false
      );
      setFormAction("edit");
      setCurrentUserId(user_id);
      setIsModalOpen(true);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlerDelete = async (user_id) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete this user?",
      onOk: async () => {
        try {
          setSubmitting(true);
          await axiosPrivate.delete(`/admin/user/${user_id}`);
          notification.success({
            message: "Successfully deleted the user.",
            placement: "bottom",
            duration: 4,
          });
          setUsers((state) => {
            const new_state = state.filter((m) => m.user_id !== user_id);
            return [...new_state];
          });
        } catch (err) {
          notification.error({
            message: err.message,
            placement: "bottom",
            duration: 4,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });
  };

  const handleAddSubmit = async (data) => {
    try {
      setSubmitting(true);
      if (formAction === "add") {
        const res_add = await axiosPrivate.post(`/admin/users/`, data);
        notification.success({
          message: "Successfully created new user.",
          placement: "bottom",
          duration: 4,
        });
        setUsers((state) => {
          return [...state, { ...res_add.data }];
        });
      } else if (formAction === "edit") {
        const res_update_user = await axiosPrivate.patch(
          `/admin/user/${currentUserId}`,
          { ...data }
        );
        notification.success({
          message: `Successfully updated user ${data.display_name}.`,
          placement: "bottom",
          duration: 4,
        });
        setUsers((state) => {
          let found_user_index = state.findIndex(
            (metre) => metre.user_id === currentUserId
          );
          state[found_user_index] = { ...res_update_user.data };
          return [...state];
        });
      }
      reset();
      setIsModalOpen(false);
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottom",
        duration: 4,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Content
      style={{
        display: "flex",
        flexFlow: "column",
        overflow: "hidden",
        gap: 5,
      }}
    >
      {contextHolderConfirm}
      <Modal
        title={formAction === "add" ? "Add New User" : "Edit User"}
        width={600}
        styles={{
          body: { maxHeight: 500, overflowX: "hidden", overflowY: "scroll" },
        }}
        open={isModalOpen}
        onOk={handleOk}
        okText="Save"
        okButtonProps={{
          disabled: submitting,
          loading: submitting,
          htmlType: "submit",
        }}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: submitting }}
        destroyOnClose
      >
        <Content>
          <AntForm
            labelCol={{ span: 8, offset: 0 }}
            labelAlign="left"
            labelWrap
            requiredMark
            ref={formRef}
            layout="horizontal"
            onFinish={handleSubmit(handleAddSubmit)}
            disabled={submitting}
          >
            <Controller
              name="username"
              control={control}
              rules={{ required: true, minLength: 4, maxLength: 50 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Username"
                    required
                    validateStatus={errors.username ? "error" : "success"}
                  >
                    <Input {...field} minLength={4} maxLength={50} showCount />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: formAction === "add" ? true : false,
                minLength: 4,
                maxLength: 50,
              }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Password"
                    required={formAction === "add" ? true : false}
                    validateStatus={errors.password ? "error" : "success"}
                    tooltip={
                      formAction === "add" ? "" : "Leave blank if no changes."
                    }
                  >
                    <Input.Password
                      {...field}
                      minLength={4}
                      maxLength={50}
                      showCount
                    />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="roles"
              control={control}
              rules={{ required: true, maxLength: 100 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Roles"
                    required
                    validateStatus={errors.roles ? "error" : "success"}
                  >
                    <Select
                      {...field}
                      options={
                        ["9000"].includes(roles)
                          ? [
                              { value: "1000", label: "User" },
                              { value: "2000", label: "Operator" },
                              { value: "8000", label: "Admin" },
                              { value: "9000", label: "SysAdmin" },
                            ]
                          : [
                              { value: "1000", label: "User" },
                              { value: "2000", label: "Operator" },
                              { value: "8000", label: "Admin" },
                            ]
                      }
                    />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="permitted_locations"
              control={control}
              rules={{ required: true, maxLength: 100 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Permitted Site"
                    required
                    validateStatus={
                      errors.permitted_locations ? "error" : "success"
                    }
                  >
                    <Select {...field} mode="multiple" options={locations} />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="permitted_metres"
              control={control}
              rules={{ required: true, maxLength: 100 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Permitted Metres"
                    required
                    validateStatus={
                      errors.permitted_metres ? "error" : "success"
                    }
                  >
                    <Select {...field} mode="multiple" options={metreGroups} />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="permitted_gauges"
              control={control}
              rules={{ required: true, maxLength: 100 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Permitted Gauges"
                    required
                    validateStatus={
                      errors.permitted_gauges ? "error" : "success"
                    }
                  >
                    <Select {...field} mode="multiple" options={gaugeGroups} />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: true, maxLength: 100 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Email"
                    required
                    validateStatus={errors.email ? "error" : "success"}
                  >
                    <Input {...field} maxLength={100} showCount />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="display_name"
              control={control}
              rules={{ maxLength: 50 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Display Name"
                    validateStatus={errors.display_name ? "error" : "success"}
                  >
                    <Input {...field} maxLength={50} showCount />
                  </AntForm.Item>
                );
              }}
            />{" "}
            <Controller
              name="location"
              control={control}
              rules={{ maxLength: 100 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Location"
                    validateStatus={errors.location ? "error" : "success"}
                  >
                    <Select
                      {...field}
                      options={officeLocations?.map((office) => ({
                        label: office.location,
                        value: office.location,
                      }))}
                    />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="department"
              control={control}
              rules={{ maxLength: 100 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Department"
                    validateStatus={errors.department ? "error" : "success"}
                  >
                    <Select
                      {...field}
                      options={[
                        {
                          value: "General Management",
                          label: "General Management",
                        },
                        {
                          value: "Marketing",
                          label: "Marketing",
                        },
                        { value: "Operations", label: "Operations" },
                        { value: "Finance", label: "Finance" },
                        { value: "Sales", label: "Sales" },
                        { value: "Human Resource", label: "Human Resource" },
                        { value: "Purchase", label: "Purchase" },
                        { value: "Business", label: "Business" },
                        { value: "Engineering", label: "Engineering" },
                        {
                          value: "Information Technology",
                          label: "Information Technology",
                        },
                        { value: "Legal", label: "Legal" },
                        { value: "Production", label: "Production" },
                        { value: "Risk Management", label: "Risk Management" },
                        { value: "Maintenance", label: "Maintenance" },
                        {
                          value: "Customer Service",
                          label: "Customer Service",
                        },
                        {
                          value: "Transportation",
                          label: "Transportation",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="mobile"
              control={control}
              rules={{ maxLength: 50 }}
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="Phone"
                    validateStatus={errors.mobile ? "error" : "success"}
                  >
                    <Input {...field} maxLength={50} showCount />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="app_enabled"
              control={control}
              render={({ field }) => {
                field.checked = field.value;
                delete field.value;
                return (
                  <AntForm.Item style={{ marginBottom: 10 }}>
                    <Checkbox {...field}>PRM Mobile Enabled</Checkbox>
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="app_maintenance_access"
              control={control}
              rules={{ required: true, maxLength: 30 }}
              defaultValue="none"
              render={({ field }) => {
                return (
                  <AntForm.Item
                    style={{ marginBottom: 10 }}
                    label="APP Notes Access"
                    required
                    validateStatus={
                      errors.permitted_locations ? "error" : "success"
                    }
                  >
                    <Select
                      {...field}
                      options={[
                        { label: "NONE", value: "none" },
                        { label: "VIEWER", value: "view" },
                        { label: "EDITOR", value: "edit" },
                      ]}
                    />
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="app_push_notification"
              control={control}
              render={({ field }) => {
                field.checked = field.value;
                delete field.value;
                return (
                  <AntForm.Item style={{ marginBottom: 10 }}>
                    <Checkbox {...field}>PRM Mobile APN Enabled</Checkbox>
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="app_device_connection_notification"
              control={control}
              render={({ field }) => {
                field.checked = field.value;
                delete field.value;
                return (
                  <AntForm.Item style={{ marginBottom: 10 }}>
                    <Checkbox {...field}>PRM Device Status APN</Checkbox>
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="app_load_notification"
              control={control}
              render={({ field }) => {
                field.checked = field.value;
                delete field.value;
                return (
                  <AntForm.Item style={{ marginBottom: 10 }}>
                    <Checkbox {...field}>PRM Mobile Load APN</Checkbox>
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="app_delivery_notification"
              control={control}
              render={({ field }) => {
                field.checked = field.value;
                delete field.value;
                return (
                  <AntForm.Item style={{ marginBottom: 10 }}>
                    <Checkbox {...field}>PRM Mobile Delivery APN</Checkbox>
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="app_tank_notification"
              control={control}
              render={({ field }) => {
                field.checked = field.value;
                delete field.value;
                return (
                  <AntForm.Item style={{ marginBottom: 10 }}>
                    <Checkbox {...field}>
                      PRM Mobile Tank Monitoring APN
                    </Checkbox>
                  </AntForm.Item>
                );
              }}
            />
            <Controller
              name="disabled"
              control={control}
              render={({ field }) => {
                field.checked = field.value;
                delete field.value;
                return (
                  <AntForm.Item style={{ marginBottom: 10 }}>
                    <Checkbox {...field}>Disabled</Checkbox>
                  </AntForm.Item>
                );
              }}
            />
          </AntForm>
        </Content>
      </Modal>
      <div className="admin-metre-toolbar">
        <div>
          <Space>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={async () => {
                await loadUsers();
              }}
            />
            <Button
              size="small"
              onClick={() => {
                handlerShowForm();
              }}
              disabled={isLoading}
            >
              Add User
            </Button>
          </Space>
        </div>
        <div>
          <Space>
            <Select
              size="small"
              style={{ width: 200 }}
              onChange={handlerSearchUserDisabledChanged}
              defaultValue=""
              options={[
                { value: "", label: "All Users" },
                { value: 0, label: "Active Users" },
                { value: 1, label: "Disabled Users" },
              ]}
            />
            <Search
              size="small"
              placeholder="Key in to search"
              style={{ width: 300 }}
              onChange={handlerSearchChange}
            />
          </Space>
        </div>
      </div>
      <div className="admin-table-wrap">
        <Table
          sticky
          pagination={{ position: ["none", "none"], defaultPageSize: 200 }}
          bordered
          columns={columns}
          rowKey={(user) => user.user_id}
          dataSource={users}
          loading={isLoading}
          showSorterTooltip={false}
          rowClassName={(record) => record.disabled && "disabled-record"}
        />
      </div>
    </Content>
  );
};

export default AdminUsers;
